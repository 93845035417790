"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiGatewayWebsocketApi = exports.EPaymentSubscriptionStatus = exports.EPaymentPaymentStatus = exports.EPaymentSubscriptionPaymentCycle = exports.ECreditsCreditTransactionStatus = exports.ECreditsCreditTransactionPurpose = exports.ECommunicationChatType = exports.EUserRole = exports.ECmsDegeniaVacancyType = exports.ECmsDegeniaGenericPageType = exports.ECmsDegeniaTextType = void 0;
var ECmsDegeniaTextType;
(function (ECmsDegeniaTextType) {
    ECmsDegeniaTextType["longColumn"] = "longColumn";
    ECmsDegeniaTextType["shortColumn"] = "shortColumn";
    ECmsDegeniaTextType["twoColumns"] = "twoColumns";
})(ECmsDegeniaTextType = exports.ECmsDegeniaTextType || (exports.ECmsDegeniaTextType = {}));
var ECmsDegeniaGenericPageType;
(function (ECmsDegeniaGenericPageType) {
    ECmsDegeniaGenericPageType["damageReport"] = "damageReport";
    ECmsDegeniaGenericPageType["insurance"] = "insurance";
    ECmsDegeniaGenericPageType["jobs"] = "jobs";
    ECmsDegeniaGenericPageType["news"] = "news";
    ECmsDegeniaGenericPageType["press"] = "press";
    ECmsDegeniaGenericPageType["team"] = "team";
})(ECmsDegeniaGenericPageType = exports.ECmsDegeniaGenericPageType || (exports.ECmsDegeniaGenericPageType = {}));
var ECmsDegeniaVacancyType;
(function (ECmsDegeniaVacancyType) {
    ECmsDegeniaVacancyType["apprenticeship"] = "apprenticeship";
    ECmsDegeniaVacancyType["jobOffer"] = "jobOffer";
})(ECmsDegeniaVacancyType = exports.ECmsDegeniaVacancyType || (exports.ECmsDegeniaVacancyType = {}));
var enum_1 = require("@teamparallax/common-libraries/lib/rbac/enum");
Object.defineProperty(exports, "EUserRole", { enumerable: true, get: function () { return enum_1.EUserRole; } });
var ECommunicationChatType;
(function (ECommunicationChatType) {
    ECommunicationChatType[ECommunicationChatType["dialog"] = 0] = "dialog";
    ECommunicationChatType[ECommunicationChatType["general"] = 1] = "general";
    ECommunicationChatType[ECommunicationChatType["group"] = 2] = "group";
})(ECommunicationChatType = exports.ECommunicationChatType || (exports.ECommunicationChatType = {}));
var ECreditsCreditTransactionPurpose;
(function (ECreditsCreditTransactionPurpose) {
    ECreditsCreditTransactionPurpose["spending"] = "spending";
    ECreditsCreditTransactionPurpose["stockUp"] = "stockUp";
})(ECreditsCreditTransactionPurpose = exports.ECreditsCreditTransactionPurpose || (exports.ECreditsCreditTransactionPurpose = {}));
var ECreditsCreditTransactionStatus;
(function (ECreditsCreditTransactionStatus) {
    ECreditsCreditTransactionStatus["cancelled"] = "cancelled";
    ECreditsCreditTransactionStatus["completed"] = "completed";
    ECreditsCreditTransactionStatus["failed"] = "failed";
    ECreditsCreditTransactionStatus["pending"] = "pending";
})(ECreditsCreditTransactionStatus = exports.ECreditsCreditTransactionStatus || (exports.ECreditsCreditTransactionStatus = {}));
var EPaymentSubscriptionPaymentCycle;
(function (EPaymentSubscriptionPaymentCycle) {
    EPaymentSubscriptionPaymentCycle["day"] = "day";
    EPaymentSubscriptionPaymentCycle["month"] = "month";
    EPaymentSubscriptionPaymentCycle["week"] = "week";
    EPaymentSubscriptionPaymentCycle["year"] = "year";
})(EPaymentSubscriptionPaymentCycle = exports.EPaymentSubscriptionPaymentCycle || (exports.EPaymentSubscriptionPaymentCycle = {}));
var EPaymentPaymentStatus;
(function (EPaymentPaymentStatus) {
    EPaymentPaymentStatus["cancelled"] = "cancelled";
    EPaymentPaymentStatus["failed"] = "failed";
    EPaymentPaymentStatus["paid"] = "paid";
    EPaymentPaymentStatus["unpaid"] = "unpaid";
})(EPaymentPaymentStatus = exports.EPaymentPaymentStatus || (exports.EPaymentPaymentStatus = {}));
var EPaymentSubscriptionStatus;
(function (EPaymentSubscriptionStatus) {
    EPaymentSubscriptionStatus["active"] = "active";
    EPaymentSubscriptionStatus["canceled"] = "canceled";
    EPaymentSubscriptionStatus["incomplete"] = "incomplete";
    EPaymentSubscriptionStatus["unpaid"] = "unpaid";
})(EPaymentSubscriptionStatus = exports.EPaymentSubscriptionStatus || (exports.EPaymentSubscriptionStatus = {}));
class ApiGatewayWebsocketApi {
    constructor(client) {
        this._client = client;
    }
    get client() {
        return this._client;
    }
    calendarControllerCreateNewEvent(newEvent, sendMeta) {
        return this._client.send(Object.assign({ route: "/CalendarController/createNewEvent" }, sendMeta), newEvent);
    }
    calendarControllerDeleteEvent(eventId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CalendarController/deleteEvent" }, sendMeta), eventId);
    }
    calendarControllerGetCalendarsByUserId(userId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CalendarController/getCalendarsByUserId" }, sendMeta), userId);
    }
    calendarControllerGetEventDetails(eventId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CalendarController/getEventDetails" }, sendMeta), eventId);
    }
    calendarControllerUpdateEvent(eventId, newEvent, sendMeta) {
        return this._client.send(Object.assign({ route: "/CalendarController/updateEvent" }, sendMeta), eventId, newEvent);
    }
    cmsControllerCreateDegeniaArticle(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/createDegeniaArticle" }, sendMeta), payload);
    }
    cmsControllerCreateDegeniaAward(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/createDegeniaAward" }, sendMeta), payload);
    }
    cmsControllerCreateDegeniaCompanyLogo(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/createDegeniaCompanyLogo" }, sendMeta), payload);
    }
    cmsControllerCreateDegeniaEvent(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/createDegeniaEvent" }, sendMeta), payload);
    }
    cmsControllerCreateDegeniaGenericPage(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/createDegeniaGenericPage" }, sendMeta), payload);
    }
    cmsControllerCreateDegeniaGenericText(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/createDegeniaGenericText" }, sendMeta), payload);
    }
    cmsControllerCreateDegeniaInsurance(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/createDegeniaInsurance" }, sendMeta), payload);
    }
    cmsControllerCreateDegeniaMicrosite(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/createDegeniaMicrosite" }, sendMeta), payload);
    }
    cmsControllerCreateDegeniaMrMoneyiFrame(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/createDegeniaMrMoneyiFrame" }, sendMeta), payload);
    }
    cmsControllerCreateDegeniaNewsletter(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/createDegeniaNewsletter" }, sendMeta), payload);
    }
    cmsControllerCreateDegeniaNote(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/createDegeniaNote" }, sendMeta), payload);
    }
    cmsControllerCreateDegeniaTeamMember(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/createDegeniaTeamMember" }, sendMeta), payload);
    }
    cmsControllerCreateDegeniaVacancy(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/createDegeniaVacancy" }, sendMeta), payload);
    }
    cmsControllerCreateDegeniaWebinar(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/createDegeniaWebinar" }, sendMeta), payload);
    }
    cmsControllerGetDegeniaArticle(entryId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getDegeniaArticle" }, sendMeta), entryId);
    }
    cmsControllerGetDegeniaAward(entryId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getDegeniaAward" }, sendMeta), entryId);
    }
    cmsControllerGetDegeniaCompanyLogo(entryId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getDegeniaCompanyLogo" }, sendMeta), entryId);
    }
    cmsControllerGetDegeniaEvent(entryId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getDegeniaEvent" }, sendMeta), entryId);
    }
    cmsControllerGetDegeniaGenericPage(entryId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getDegeniaGenericPage" }, sendMeta), entryId);
    }
    cmsControllerGetDegeniaGenericText(entryId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getDegeniaGenericText" }, sendMeta), entryId);
    }
    cmsControllerGetDegeniaInsurance(entryId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getDegeniaInsurance" }, sendMeta), entryId);
    }
    cmsControllerGetDegeniaLandingpage(sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getDegeniaLandingpage" }, sendMeta));
    }
    cmsControllerGetDegeniaMicrosite(entryId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getDegeniaMicrosite" }, sendMeta), entryId);
    }
    cmsControllerGetDegeniaMicrositeDescription(sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getDegeniaMicrositeDescription" }, sendMeta));
    }
    cmsControllerGetDegeniaMrMoneyiFrame(entryId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getDegeniaMrMoneyiFrame" }, sendMeta), entryId);
    }
    cmsControllerGetDegeniaNewsletter(entryId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getDegeniaNewsletter" }, sendMeta), entryId);
    }
    cmsControllerGetDegeniaNews(entryId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getDegeniaNews" }, sendMeta), entryId);
    }
    cmsControllerGetDegeniaNote(entryId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getDegeniaNote" }, sendMeta), entryId);
    }
    cmsControllerGetDegeniaTeamMember(entryId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getDegeniaTeamMember" }, sendMeta), entryId);
    }
    cmsControllerGetDegeniaVacancy(entryId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getDegeniaVacancy" }, sendMeta), entryId);
    }
    cmsControllerGetDegeniaWebinar(entryId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getDegeniaWebinar" }, sendMeta), entryId);
    }
    cmsControllerUpdateDegeniaArticle(entryId, payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateDegeniaArticle" }, sendMeta), entryId, payload);
    }
    cmsControllerUpdateDegeniaAward(entryId, payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateDegeniaAward" }, sendMeta), entryId, payload);
    }
    cmsControllerUpdateDegeniaCompanyLogo(entryId, payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateDegeniaCompanyLogo" }, sendMeta), entryId, payload);
    }
    cmsControllerUpdateDegeniaEvent(entryId, payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateDegeniaEvent" }, sendMeta), entryId, payload);
    }
    cmsControllerUpdateDegeniaGenericPage(entryId, payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateDegeniaGenericPage" }, sendMeta), entryId, payload);
    }
    cmsControllerUpdateDegeniaGenericText(entryId, payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateDegeniaGenericText" }, sendMeta), entryId, payload);
    }
    cmsControllerUpdateDegeniaInsurance(entryId, payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateDegeniaInsurance" }, sendMeta), entryId, payload);
    }
    cmsControllerUpdateDegeniaLandingpage(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateDegeniaLandingpage" }, sendMeta), payload);
    }
    cmsControllerUpdateDegeniaMicrosite(entryId, payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateDegeniaMicrosite" }, sendMeta), entryId, payload);
    }
    cmsControllerUpdateDegeniaMicrositeDescription(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateDegeniaMicrositeDescription" }, sendMeta), payload);
    }
    cmsControllerUpdateDegeniaMrMoneyiFrame(entryId, payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateDegeniaMrMoneyiFrame" }, sendMeta), entryId, payload);
    }
    cmsControllerUpdateDegeniaNewsletter(entryId, payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateDegeniaNewsletter" }, sendMeta), entryId, payload);
    }
    cmsControllerUpdateDegeniaNote(entryId, payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateDegeniaNote" }, sendMeta), entryId, payload);
    }
    cmsControllerUpdateDegeniaTeamMember(entryId, payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateDegeniaTeamMember" }, sendMeta), entryId, payload);
    }
    cmsControllerUpdateDegeniaVacancy(entryId, payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateDegeniaVacancy" }, sendMeta), entryId, payload);
    }
    cmsControllerUpdateDegeniaWebinar(entryId, payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateDegeniaWebinar" }, sendMeta), entryId, payload);
    }
    cmsControllerCreateEmail(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/createEmail" }, sendMeta), payload);
    }
    cmsControllerGetEmail(entryId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getEmail" }, sendMeta), entryId);
    }
    cmsControllerUpdateEmail(entryId, payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateEmail" }, sendMeta), entryId, payload);
    }
    cmsControllerCreateNarkumaApplication(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/createNarkumaApplication" }, sendMeta), payload);
    }
    cmsControllerGetNarkumaApplication(entryId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/getNarkumaApplication" }, sendMeta), entryId);
    }
    cmsControllerUpdateNarkumaApplication(entryId, payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CmsController/updateNarkumaApplication" }, sendMeta), entryId, payload);
    }
    communicationControllerCreateChat(recipient, sendMeta) {
        return this._client.send(Object.assign({ route: "/CommunicationController/createChat" }, sendMeta), recipient);
    }
    communicationControllerGetChatsOverview(sendMeta) {
        return this._client.send(Object.assign({ route: "/CommunicationController/getChatsOverview" }, sendMeta));
    }
    communicationControllerGetMessagesOfChat(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/CommunicationController/getMessagesOfChat" }, sendMeta), payload);
    }
    communicationControllerSendMessage(message, sendMeta) {
        return this._client.send(Object.assign({ route: "/CommunicationController/sendMessage" }, sendMeta), message);
    }
    creditsControllerGetAllCreditOfferings(sendMeta) {
        return this._client.send(Object.assign({ route: "/CreditsController/getAllCreditOfferings" }, sendMeta));
    }
    creditsControllerGetActiveCreditOfferings(sendMeta) {
        return this._client.send(Object.assign({ route: "/CreditsController/getActiveCreditOfferings" }, sendMeta));
    }
    creditsControllerGetArchivedCreditOfferings(sendMeta) {
        return this._client.send(Object.assign({ route: "/CreditsController/getArchivedCreditOfferings" }, sendMeta));
    }
    creditsControllerGetCreditOfferingById(id, sendMeta) {
        return this._client.send(Object.assign({ route: "/CreditsController/getCreditOfferingById" }, sendMeta), id);
    }
    creditsControllerCreateCreditOffering(creditOffering, sendMeta) {
        return this._client.send(Object.assign({ route: "/CreditsController/createCreditOffering" }, sendMeta), creditOffering);
    }
    creditsControllerGetUserTransactions(userId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CreditsController/getUserTransactions" }, sendMeta), userId);
    }
    creditsControllerGetUserBalance(userId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CreditsController/getUserBalance" }, sendMeta), userId);
    }
    creditsControllerPurchaseCredits(creditPurchaseIntent, sendMeta) {
        return this._client.send(Object.assign({ route: "/CreditsController/purchaseCredits" }, sendMeta), creditPurchaseIntent);
    }
    creditsControllerCreateTransaction(creditTransaction, sendMeta) {
        return this._client.send(Object.assign({ route: "/CreditsController/createTransaction" }, sendMeta), creditTransaction);
    }
    creditsControllerCancelTransaction(transactionId, sendMeta) {
        return this._client.send(Object.assign({ route: "/CreditsController/cancelTransaction" }, sendMeta), transactionId);
    }
    creditsControllerSpendCredits(creditSpendIntent, sendMeta) {
        return this._client.send(Object.assign({ route: "/CreditsController/spendCredits" }, sendMeta), creditSpendIntent);
    }
    fileControllerGetUserFile(userId, path, isPublic, sendMeta) {
        return this._client.send(Object.assign({ route: "/FileController/getUserFile" }, sendMeta), userId, path, isPublic);
    }
    fileControllerShowUserFiles(userId, path, isPublic, sendMeta) {
        return this._client.send(Object.assign({ route: "/FileController/showUserFiles" }, sendMeta), userId, path, isPublic);
    }
    fileControllerRemoveFile(bucketName, filePath, userId, sendMeta) {
        return this._client.send(Object.assign({ route: "/FileController/removeFile" }, sendMeta), bucketName, filePath, userId);
    }
    fileControllerRemoveDirectory(bucketName, directory, sendMeta) {
        return this._client.send(Object.assign({ route: "/FileController/removeDirectory" }, sendMeta), bucketName, directory);
    }
    mailControllerSendStrapiMail(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/MailController/sendStrapiMail" }, sendMeta), payload);
    }
    mailControllerSendDegeniaBecomeOurPartner(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/MailController/sendDegeniaBecomeOurPartner" }, sendMeta), payload);
    }
    mailControllerSendDegeniaDirectMessage(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/MailController/sendDegeniaDirectMessage" }, sendMeta), payload);
    }
    mailControllerSendDegeniaAddressChange(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/MailController/sendDegeniaAddressChange" }, sendMeta), payload);
    }
    mailControllerSendDegeniaBankChange(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/MailController/sendDegeniaBankChange" }, sendMeta), payload);
    }
    mailControllerSendPasswordResetMail(payload, sendMeta) {
        return this._client.send(Object.assign({ route: "/MailController/sendPasswordResetMail" }, sendMeta), payload);
    }
    paymentControllerGetCustomers(sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/getCustomers" }, sendMeta));
    }
    paymentControllerGetCustomerById(customerId, sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/getCustomerById" }, sendMeta), customerId);
    }
    paymentControllerGetCustomerByUserId(userId, sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/getCustomerByUserId" }, sendMeta), userId);
    }
    paymentControllerCreateCustomer(customer, sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/createCustomer" }, sendMeta), customer);
    }
    paymentControllerUpdateCustomer(customer, sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/updateCustomer" }, sendMeta), customer);
    }
    paymentControllerDeleteCustomer(customerId, sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/deleteCustomer" }, sendMeta), customerId);
    }
    paymentControllerGetProducts(sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/getProducts" }, sendMeta));
    }
    paymentControllerGetProductById(productId, sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/getProductById" }, sendMeta), productId);
    }
    paymentControllerCreateProduct(product, sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/createProduct" }, sendMeta), product);
    }
    paymentControllerArchiveProduct(productId, sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/archiveProduct" }, sendMeta), productId);
    }
    paymentControllerGetSubscriptions(sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/getSubscriptions" }, sendMeta));
    }
    paymentControllerGetSubscriptionById(subscriptionId, sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/getSubscriptionById" }, sendMeta), subscriptionId);
    }
    paymentControllerCreateSubscription(subscription, sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/createSubscription" }, sendMeta), subscription);
    }
    paymentControllerArchiveSubscription(subscriptionId, sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/archiveSubscription" }, sendMeta), subscriptionId);
    }
    paymentControllerStripeWebhook(event, sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/stripeWebhook" }, sendMeta), event);
    }
    paymentControllerGetUserPurchasedProducts(userId, sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/getUserPurchasedProducts" }, sendMeta), userId);
    }
    paymentControllerCreateProductCheckout(intent, sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/createProductCheckout" }, sendMeta), intent);
    }
    paymentControllerGetUserPurchasedSubscriptions(userId, sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/getUserPurchasedSubscriptions" }, sendMeta), userId);
    }
    paymentControllerCreateSubscriptionCheckout(checkout, sendMeta) {
        return this._client.send(Object.assign({ route: "/PaymentController/createSubscriptionCheckout" }, sendMeta), checkout);
    }
    userControllerActivate(token, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/activate" }, sendMeta), token);
    }
    userControllerAuthenticate(credentials, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/authenticate" }, sendMeta), credentials);
    }
    userControllerLogout(sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/logout" }, sendMeta));
    }
    userControllerCreateNewUser(iNewUser, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/createNewUser" }, sendMeta), iNewUser);
    }
    userControllerRemoveUser(userId, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/removeUser" }, sendMeta), userId);
    }
    userControllerGetAll(sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/getAll" }, sendMeta));
    }
    userControllerGetAvailableRoles(sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/getAvailableRoles" }, sendMeta));
    }
    userControllerGetUserById(userId, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/getUserById" }, sendMeta), userId);
    }
    userControllerGetUserByIds(userIds, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/getUserByIds" }, sendMeta), userIds);
    }
    userControllerGetUserByRoleValue(roleName, roleValues, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/getUserByRoleValue" }, sendMeta), roleName, roleValues);
    }
    userControllerDuplicate(userId, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/duplicate" }, sendMeta), userId);
    }
    userControllerSearchByUsername(username, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/searchByUsername" }, sendMeta), username);
    }
    userControllerGiveRoleToUser(userId, role, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/giveRoleToUser" }, sendMeta), userId, role);
    }
    userControllerRemoveRoleFromUser(userId, role, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/removeRoleFromUser" }, sendMeta), userId, role);
    }
    userControllerUpdateRoleOfUser(userId, updateRole, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/updateRoleOfUser" }, sendMeta), userId, updateRole);
    }
    userControllerRegisterNewUser(iNewUser, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/registerNewUser" }, sendMeta), iNewUser);
    }
    userControllerUpdate(user, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/update" }, sendMeta), user);
    }
    userControllerUpdateSettings(updateUser, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/updateSettings" }, sendMeta), updateUser);
    }
    userControllerGetUserSettings(userId, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/getUserSettings" }, sendMeta), userId);
    }
    userControllerResetPasswordRequest(request, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/resetPasswordRequest" }, sendMeta), request);
    }
    userControllerValidatePasswordRequestToken(token, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/validatePasswordRequestToken" }, sendMeta), token);
    }
    userControllerUpdateResettedPassword(update, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/updateResettedPassword" }, sendMeta), update);
    }
    userControllerUpdateTourProgress(updateTourProgress, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/updateTourProgress" }, sendMeta), updateTourProgress);
    }
    userControllerCreateTour(tour, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/createTour" }, sendMeta), tour);
    }
    userControllerGetAllTours(sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/getAllTours" }, sendMeta));
    }
    userControllerGetCurrentUserTour(userId, currentUrl, targets, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/getCurrentUserTour" }, sendMeta), userId, currentUrl, targets);
    }
    userControllerGetUserTourData(getUserTourData, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/getUserTourData" }, sendMeta), getUserTourData);
    }
    userControllerGetTourById(tourId, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/getTourById" }, sendMeta), tourId);
    }
    userControllerGetTourByNameAndProduct(name, product, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/getTourByNameAndProduct" }, sendMeta), name, product);
    }
    userControllerGetToursByName(name, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/getToursByName" }, sendMeta), name);
    }
    userControllerGetToursByProduct(product, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/getToursByProduct" }, sendMeta), product);
    }
    userControllerGetToursByUrl(url, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/getToursByUrl" }, sendMeta), url);
    }
    userControllerDeleteTour(tourId, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/deleteTour" }, sendMeta), tourId);
    }
    userControllerUpdateLastReadChatMessageDate(lastReadMessageData, sendMeta) {
        return this._client.send(Object.assign({ route: "/UserController/updateLastReadChatMessageDate" }, sendMeta), lastReadMessageData);
    }
}
exports.ApiGatewayWebsocketApi = ApiGatewayWebsocketApi;
exports.default = ApiGatewayWebsocketApi;
