"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 *
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 9.133.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentApiAxiosParamCreator = exports.MailApi = exports.MailApiFactory = exports.MailApiFp = exports.MailApiAxiosParamCreator = exports.FileApi = exports.FileApiFactory = exports.FileApiFp = exports.FileApiAxiosParamCreator = exports.DebugApi = exports.DebugApiFactory = exports.DebugApiFp = exports.DebugApiAxiosParamCreator = exports.CreditsApi = exports.CreditsApiFactory = exports.CreditsApiFp = exports.CreditsApiAxiosParamCreator = exports.CommunicationApi = exports.CommunicationApiFactory = exports.CommunicationApiFp = exports.CommunicationApiAxiosParamCreator = exports.CalendarApi = exports.CalendarApiFactory = exports.CalendarApiFp = exports.CalendarApiAxiosParamCreator = exports.CMSApi = exports.CMSApiFactory = exports.CMSApiFp = exports.CMSApiAxiosParamCreator = exports.IPaymentProductCheckoutInfoProviderEnum = exports.EUserRole = exports.EPaymentSubscriptionStatus = exports.EPaymentSubscriptionPaymentCycle = exports.EPaymentPaymentStatus = exports.ELocalisation = exports.ECreditsCreditTransactionStatus = exports.ECreditsCreditTransactionPurpose = exports.ECommunicationChatType = exports.ECmsNarkumaEntryTypeApplication = exports.ECmsDegeniaVacancyType = exports.ECmsDegeniaTextType = exports.ECmsDegeniaGenericPageType = exports.ECmsDegeniaEntryTypeWebinar = exports.ECmsDegeniaEntryTypeVideo = exports.ECmsDegeniaEntryTypeVacancy = exports.ECmsDegeniaEntryTypeNews = exports.ECmsDegeniaEntryTypeMicrosite = exports.ECmsDegeniaEntryTypeInsurance = exports.ECmsDegeniaEntryTypeGenericPage = exports.ECmsDegeniaEntryTypeEvent = void 0;
exports.UserApi = exports.UserApiFactory = exports.UserApiFp = exports.UserApiAxiosParamCreator = exports.PaymentApi = exports.PaymentApiFactory = exports.PaymentApiFp = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaEntryTypeEvent;
(function (ECmsDegeniaEntryTypeEvent) {
    ECmsDegeniaEntryTypeEvent["Event"] = "event";
})(ECmsDegeniaEntryTypeEvent = exports.ECmsDegeniaEntryTypeEvent || (exports.ECmsDegeniaEntryTypeEvent = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaEntryTypeGenericPage;
(function (ECmsDegeniaEntryTypeGenericPage) {
    ECmsDegeniaEntryTypeGenericPage["GenericPage"] = "generic-page";
})(ECmsDegeniaEntryTypeGenericPage = exports.ECmsDegeniaEntryTypeGenericPage || (exports.ECmsDegeniaEntryTypeGenericPage = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaEntryTypeInsurance;
(function (ECmsDegeniaEntryTypeInsurance) {
    ECmsDegeniaEntryTypeInsurance["Insurance"] = "insurance";
})(ECmsDegeniaEntryTypeInsurance = exports.ECmsDegeniaEntryTypeInsurance || (exports.ECmsDegeniaEntryTypeInsurance = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaEntryTypeMicrosite;
(function (ECmsDegeniaEntryTypeMicrosite) {
    ECmsDegeniaEntryTypeMicrosite["Microsite"] = "microsite";
})(ECmsDegeniaEntryTypeMicrosite = exports.ECmsDegeniaEntryTypeMicrosite || (exports.ECmsDegeniaEntryTypeMicrosite = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaEntryTypeNews;
(function (ECmsDegeniaEntryTypeNews) {
    ECmsDegeniaEntryTypeNews["NewsItem"] = "news-item";
})(ECmsDegeniaEntryTypeNews = exports.ECmsDegeniaEntryTypeNews || (exports.ECmsDegeniaEntryTypeNews = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaEntryTypeVacancy;
(function (ECmsDegeniaEntryTypeVacancy) {
    ECmsDegeniaEntryTypeVacancy["Vacancy"] = "vacancy";
})(ECmsDegeniaEntryTypeVacancy = exports.ECmsDegeniaEntryTypeVacancy || (exports.ECmsDegeniaEntryTypeVacancy = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaEntryTypeVideo;
(function (ECmsDegeniaEntryTypeVideo) {
    ECmsDegeniaEntryTypeVideo["Video"] = "video";
})(ECmsDegeniaEntryTypeVideo = exports.ECmsDegeniaEntryTypeVideo || (exports.ECmsDegeniaEntryTypeVideo = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaEntryTypeWebinar;
(function (ECmsDegeniaEntryTypeWebinar) {
    ECmsDegeniaEntryTypeWebinar["Webinar"] = "webinar";
})(ECmsDegeniaEntryTypeWebinar = exports.ECmsDegeniaEntryTypeWebinar || (exports.ECmsDegeniaEntryTypeWebinar = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaGenericPageType;
(function (ECmsDegeniaGenericPageType) {
    ECmsDegeniaGenericPageType["DamageReport"] = "damageReport";
    ECmsDegeniaGenericPageType["Insurance"] = "insurance";
    ECmsDegeniaGenericPageType["Jobs"] = "jobs";
    ECmsDegeniaGenericPageType["News"] = "news";
    ECmsDegeniaGenericPageType["Press"] = "press";
    ECmsDegeniaGenericPageType["Team"] = "team";
})(ECmsDegeniaGenericPageType = exports.ECmsDegeniaGenericPageType || (exports.ECmsDegeniaGenericPageType = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaTextType;
(function (ECmsDegeniaTextType) {
    ECmsDegeniaTextType["LongColumn"] = "longColumn";
    ECmsDegeniaTextType["ShortColumn"] = "shortColumn";
    ECmsDegeniaTextType["TwoColumns"] = "twoColumns";
})(ECmsDegeniaTextType = exports.ECmsDegeniaTextType || (exports.ECmsDegeniaTextType = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsDegeniaVacancyType;
(function (ECmsDegeniaVacancyType) {
    ECmsDegeniaVacancyType["Apprenticeship"] = "apprenticeship";
    ECmsDegeniaVacancyType["JobOffer"] = "jobOffer";
})(ECmsDegeniaVacancyType = exports.ECmsDegeniaVacancyType || (exports.ECmsDegeniaVacancyType = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECmsNarkumaEntryTypeApplication;
(function (ECmsNarkumaEntryTypeApplication) {
    ECmsNarkumaEntryTypeApplication["Application"] = "application";
})(ECmsNarkumaEntryTypeApplication = exports.ECmsNarkumaEntryTypeApplication || (exports.ECmsNarkumaEntryTypeApplication = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECommunicationChatType;
(function (ECommunicationChatType) {
    ECommunicationChatType[ECommunicationChatType["NUMBER_0"] = 0] = "NUMBER_0";
    ECommunicationChatType[ECommunicationChatType["NUMBER_1"] = 1] = "NUMBER_1";
    ECommunicationChatType[ECommunicationChatType["NUMBER_2"] = 2] = "NUMBER_2";
})(ECommunicationChatType = exports.ECommunicationChatType || (exports.ECommunicationChatType = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECreditsCreditTransactionPurpose;
(function (ECreditsCreditTransactionPurpose) {
    ECreditsCreditTransactionPurpose["Spending"] = "spending";
    ECreditsCreditTransactionPurpose["StockUp"] = "stockUp";
})(ECreditsCreditTransactionPurpose = exports.ECreditsCreditTransactionPurpose || (exports.ECreditsCreditTransactionPurpose = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ECreditsCreditTransactionStatus;
(function (ECreditsCreditTransactionStatus) {
    ECreditsCreditTransactionStatus["Cancelled"] = "cancelled";
    ECreditsCreditTransactionStatus["Completed"] = "completed";
    ECreditsCreditTransactionStatus["Failed"] = "failed";
    ECreditsCreditTransactionStatus["Pending"] = "pending";
})(ECreditsCreditTransactionStatus = exports.ECreditsCreditTransactionStatus || (exports.ECreditsCreditTransactionStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ELocalisation;
(function (ELocalisation) {
    ELocalisation["De"] = "de";
    ELocalisation["En"] = "en";
})(ELocalisation = exports.ELocalisation || (exports.ELocalisation = {}));
/**
 *
 * @export
 * @enum {string}
 */
var EPaymentPaymentStatus;
(function (EPaymentPaymentStatus) {
    EPaymentPaymentStatus["Cancelled"] = "cancelled";
    EPaymentPaymentStatus["Failed"] = "failed";
    EPaymentPaymentStatus["Paid"] = "paid";
    EPaymentPaymentStatus["Unpaid"] = "unpaid";
})(EPaymentPaymentStatus = exports.EPaymentPaymentStatus || (exports.EPaymentPaymentStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
var EPaymentSubscriptionPaymentCycle;
(function (EPaymentSubscriptionPaymentCycle) {
    EPaymentSubscriptionPaymentCycle["Day"] = "day";
    EPaymentSubscriptionPaymentCycle["Month"] = "month";
    EPaymentSubscriptionPaymentCycle["Week"] = "week";
    EPaymentSubscriptionPaymentCycle["Year"] = "year";
})(EPaymentSubscriptionPaymentCycle = exports.EPaymentSubscriptionPaymentCycle || (exports.EPaymentSubscriptionPaymentCycle = {}));
/**
 *
 * @export
 * @enum {string}
 */
var EPaymentSubscriptionStatus;
(function (EPaymentSubscriptionStatus) {
    EPaymentSubscriptionStatus["Active"] = "active";
    EPaymentSubscriptionStatus["Canceled"] = "canceled";
    EPaymentSubscriptionStatus["Incomplete"] = "incomplete";
    EPaymentSubscriptionStatus["Unpaid"] = "unpaid";
})(EPaymentSubscriptionStatus = exports.EPaymentSubscriptionStatus || (exports.EPaymentSubscriptionStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
var EUserRole;
(function (EUserRole) {
    EUserRole["Admin"] = "admin";
    EUserRole["Alliance"] = "alliance";
    EUserRole["Allianceadmin"] = "alliance:admin";
    EUserRole["Allianceuser"] = "alliance:user";
    EUserRole["DegeniaBroker"] = "degeniaBroker";
    EUserRole["DegeniaCustomer"] = "degeniaCustomer";
    EUserRole["DegeniaCustomerCorporation"] = "degeniaCustomerCorporation";
    EUserRole["DegeniaEventPlanner"] = "degeniaEventPlanner";
    EUserRole["Degeniauserduplicate"] = "degenia:user:duplicate";
    EUserRole["Disabled"] = "disabled";
    EUserRole["FrontEndManager"] = "frontEndManager";
    EUserRole["Lord"] = "lord";
    EUserRole["Mod"] = "mod";
    EUserRole["PlanetManager"] = "planetManager";
    EUserRole["User"] = "user";
    EUserRole["Userbeta"] = "user:beta";
})(EUserRole = exports.EUserRole || (exports.EUserRole = {}));
/**
    * @export
    * @enum {string}
    */
var IPaymentProductCheckoutInfoProviderEnum;
(function (IPaymentProductCheckoutInfoProviderEnum) {
    IPaymentProductCheckoutInfoProviderEnum["Stripe"] = "stripe";
    IPaymentProductCheckoutInfoProviderEnum["Empty"] = "";
})(IPaymentProductCheckoutInfoProviderEnum = exports.IPaymentProductCheckoutInfoProviderEnum || (exports.IPaymentProductCheckoutInfoProviderEnum = {}));
/**
 * CMSApi - axios parameter creator
 * @export
 */
const CMSApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {PickICmsDegeniaContentTypeArticleExcludeKeyofICmsDegeniaContentTypeArticleIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaArticle: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaArticle.');
            }
            const localVarPath = `/cms/degenia/article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaAward: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaAward.');
            }
            const localVarPath = `/cms/degenia/award`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaCompanyLogo: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaCompanyLogo.');
            }
            const localVarPath = `/cms/degenia/company-logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeEventExcludeKeyofICmsDegeniaContentTypeEventIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaEvent: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaEvent.');
            }
            const localVarPath = `/cms/degenia/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericPageExcludeKeyofICmsDegeniaContentTypeGenericPageIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaGenericPage: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaGenericPage.');
            }
            const localVarPath = `/cms/degenia/generic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericTextExcludeKeyofICmsDegeniaContentTypeGenericTextIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaGenericText: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaGenericText.');
            }
            const localVarPath = `/cms/degenia/genericText`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeInsuranceExcludeKeyofICmsDegeniaContentTypeInsuranceIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaInsurance: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaInsurance.');
            }
            const localVarPath = `/cms/degenia/insurance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeExcludeKeyofICmsDegeniaContentTypeMicrositeIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaMicrosite: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaMicrosite.');
            }
            const localVarPath = `/cms/degenia/microsite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeMrMoneyiFrameExcludeKeyofICmsDegeniaContentTypeMrMoneyiFrameIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaMrMoneyiFrame: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaMrMoneyiFrame.');
            }
            const localVarPath = `/cms/degenia/mr-money-i-frame`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeNewsletterExcludeKeyofICmsDegeniaContentTypeNewsletterIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaNewsletter: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaNewsletter.');
            }
            const localVarPath = `/cms/degenia/newsletter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeNoteExcludeKeyofICmsDegeniaContentTypeNoteIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaNote: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaNote.');
            }
            const localVarPath = `/cms/degenia/note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeTeamMemberExcludeKeyofICmsDegeniaContentTypeTeamMemberIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaTeamMember: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaTeamMember.');
            }
            const localVarPath = `/cms/degenia/team-member`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeVacancyExcludeKeyofICmsDegeniaContentTypeVacancyIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaVacancy: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaVacancy.');
            }
            const localVarPath = `/cms/degenia/vacancy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeWebinarExcludeKeyofICmsDegeniaContentTypeWebinarIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaWebinar: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createDegeniaWebinar.');
            }
            const localVarPath = `/cms/degenia/webinar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsContentTypeEmailExcludeKeyofICmsContentTypeEmailIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmail: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createEmail.');
            }
            const localVarPath = `/cms/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsNarkumaContentTypeApplicationExcludeKeyofICmsNarkumaContentTypeApplicationIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNarkumaApplication: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling createNarkumaApplication.');
            }
            const localVarPath = `/cms/narkuma/application`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaArticles: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/article`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaAwards: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/award`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaCompanyLogos: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/company-logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaEvents: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaGenericPages: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/generic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaGenericTexts: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/genericText`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaInsurances: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/insurance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaMicrosites: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/microsite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaMrMoneyiFrames: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/mr-money-i-frame`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNews: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNewsletters: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/newsletter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNotes: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaTeamMembers: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/team-member`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaVacancies: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/vacancy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaWebinars: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/webinar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmails: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNarkumaApplications: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/narkuma/applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaArticle: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaArticle.');
            }
            const localVarPath = `/cms/degenia/article/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaAward: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaAward.');
            }
            const localVarPath = `/cms/degenia/award/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaCompanyLogo: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaCompanyLogo.');
            }
            const localVarPath = `/cms/degenia/company-logo/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaEvent: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaEvent.');
            }
            const localVarPath = `/cms/degenia/event/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaGenericPage: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaGenericPage.');
            }
            const localVarPath = `/cms/degenia/generic/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaGenericText: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaGenericText.');
            }
            const localVarPath = `/cms/degenia/genericText/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaInsurance: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaInsurance.');
            }
            const localVarPath = `/cms/degenia/insurance/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaLandingpage: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/single/landingpage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMicrosite: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaMicrosite.');
            }
            const localVarPath = `/cms/degenia/microsite/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMicrositeDescription: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/cms/degenia/single/microsite-description`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMrMoneyiFrame: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaMrMoneyiFrame.');
            }
            const localVarPath = `/cms/degenia/mr-money-i-frame/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNews: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaNews.');
            }
            const localVarPath = `/cms/degenia/news/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNewsletter: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaNewsletter.');
            }
            const localVarPath = `/cms/degenia/newsletter/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNote: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaNote.');
            }
            const localVarPath = `/cms/degenia/note/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaTeamMember: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaTeamMember.');
            }
            const localVarPath = `/cms/degenia/team-member/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaVacancy: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaVacancy.');
            }
            const localVarPath = `/cms/degenia/vacancy/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaWebinar: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getDegeniaWebinar.');
            }
            const localVarPath = `/cms/degenia/webinar/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmail: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getEmail.');
            }
            const localVarPath = `/cms/email/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNarkumaApplication: (entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling getNarkumaApplication.');
            }
            const localVarPath = `/cms/narkuma/application/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaSearchConfigExcludeKeyofICmsDegeniaSearchConfigContentTypesToSearchIn} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInDegeniaHomepage: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling searchInDegeniaHomepage.');
            }
            const localVarPath = `/cms/degenia/search/homepage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaSearchConfigExcludeKeyofICmsDegeniaSearchConfigContentTypesToSearchIn} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInMyDegenia: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling searchInMyDegenia.');
            }
            const localVarPath = `/cms/degenia/search/mydegenia`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsNarkumaSearchConfigExcludeKeyofICmsNarkumaSearchConfigContentTypesToSearchIn} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInNarkuma: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling searchInNarkuma.');
            }
            const localVarPath = `/cms/narkuma/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeArticleExcludeKeyofICmsDegeniaContentTypeArticleIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaArticle: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaArticle.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaArticle.');
            }
            const localVarPath = `/cms/degenia/article/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaAward: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaAward.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaAward.');
            }
            const localVarPath = `/cms/degenia/award/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaCompanyLogo: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaCompanyLogo.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaCompanyLogo.');
            }
            const localVarPath = `/cms/degenia/company-logo/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeEventExcludeKeyofICmsDegeniaContentTypeEventIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaEvent: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaEvent.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaEvent.');
            }
            const localVarPath = `/cms/degenia/event/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericPageExcludeKeyofICmsDegeniaContentTypeGenericPageIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaGenericPage: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaGenericPage.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaGenericPage.');
            }
            const localVarPath = `/cms/degenia/generic/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericTextExcludeKeyofICmsDegeniaContentTypeGenericTextIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaGenericText: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaGenericText.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaGenericText.');
            }
            const localVarPath = `/cms/degenia/genericText/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeInsuranceExcludeKeyofICmsDegeniaContentTypeInsuranceIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaInsurance: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaInsurance.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaInsurance.');
            }
            const localVarPath = `/cms/degenia/insurance/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeLandingpageExcludeKeyofICmsDegeniaContentTypeLandingpageIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaLandingpage: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaLandingpage.');
            }
            const localVarPath = `/cms/degenia/landingpage/{entryId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeExcludeKeyofICmsDegeniaContentTypeMicrositeIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMicrosite: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaMicrosite.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaMicrosite.');
            }
            const localVarPath = `/cms/degenia/microsite/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeDescriptionExcludeKeyofICmsDegeniaContentTypeMicrositeDescriptionIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMicrositeDescription: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaMicrositeDescription.');
            }
            const localVarPath = `/cms/degenia/microsite-description/{entryId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeMrMoneyiFrameExcludeKeyofICmsDegeniaContentTypeMrMoneyiFrameIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMrMoneyiFrame: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaMrMoneyiFrame.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaMrMoneyiFrame.');
            }
            const localVarPath = `/cms/degenia/mr-money-i-frame/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeNewsletterExcludeKeyofICmsDegeniaContentTypeNewsletterIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaNewsletter: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaNewsletter.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaNewsletter.');
            }
            const localVarPath = `/cms/degenia/newsletter/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeNoteExcludeKeyofICmsDegeniaContentTypeNoteIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaNote: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaNote.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaNote.');
            }
            const localVarPath = `/cms/degenia/note/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeTeamMemberExcludeKeyofICmsDegeniaContentTypeTeamMemberIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaTeamMember: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaTeamMember.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaTeamMember.');
            }
            const localVarPath = `/cms/degenia/team-member/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeVacancyExcludeKeyofICmsDegeniaContentTypeVacancyIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaVacancy: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaVacancy.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaVacancy.');
            }
            const localVarPath = `/cms/degenia/vacancy/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsDegeniaContentTypeWebinarExcludeKeyofICmsDegeniaContentTypeWebinarIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaWebinar: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateDegeniaWebinar.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateDegeniaWebinar.');
            }
            const localVarPath = `/cms/degenia/webinar/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsContentTypeEmailExcludeKeyofICmsContentTypeEmailIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmail: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateEmail.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateEmail.');
            }
            const localVarPath = `/cms/email/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {PickICmsNarkumaContentTypeApplicationExcludeKeyofICmsNarkumaContentTypeApplicationIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNarkumaApplication: (body, entryId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling updateNarkumaApplication.');
            }
            // verify required parameter 'entryId' is not null or undefined
            if (entryId === null || entryId === undefined) {
                throw new base_1.RequiredError('entryId', 'Required parameter entryId was null or undefined when calling updateNarkumaApplication.');
            }
            const localVarPath = `/cms/narkuma/application/{entryId}`
                .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.CMSApiAxiosParamCreator = CMSApiAxiosParamCreator;
/**
 * CMSApi - functional programming interface
 * @export
 */
const CMSApiFp = function (configuration) {
    return {
        /**
         *
         * @param {PickICmsDegeniaContentTypeArticleExcludeKeyofICmsDegeniaContentTypeArticleIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaArticle(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaArticle(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaAward(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaAward(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaCompanyLogo(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaCompanyLogo(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeEventExcludeKeyofICmsDegeniaContentTypeEventIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaEvent(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaEvent(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericPageExcludeKeyofICmsDegeniaContentTypeGenericPageIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaGenericPage(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaGenericPage(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericTextExcludeKeyofICmsDegeniaContentTypeGenericTextIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaGenericText(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaGenericText(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeInsuranceExcludeKeyofICmsDegeniaContentTypeInsuranceIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaInsurance(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaInsurance(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeExcludeKeyofICmsDegeniaContentTypeMicrositeIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaMicrosite(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaMicrosite(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMrMoneyiFrameExcludeKeyofICmsDegeniaContentTypeMrMoneyiFrameIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaMrMoneyiFrame(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaMrMoneyiFrame(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeNewsletterExcludeKeyofICmsDegeniaContentTypeNewsletterIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaNewsletter(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaNewsletter(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeNoteExcludeKeyofICmsDegeniaContentTypeNoteIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaNote(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaNote(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeTeamMemberExcludeKeyofICmsDegeniaContentTypeTeamMemberIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaTeamMember(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaTeamMember(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeVacancyExcludeKeyofICmsDegeniaContentTypeVacancyIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaVacancy(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaVacancy(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeWebinarExcludeKeyofICmsDegeniaContentTypeWebinarIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaWebinar(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createDegeniaWebinar(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsContentTypeEmailExcludeKeyofICmsContentTypeEmailIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmail(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createEmail(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsNarkumaContentTypeApplicationExcludeKeyofICmsNarkumaContentTypeApplicationIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNarkumaApplication(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).createNarkumaApplication(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaArticles(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaArticles(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaAwards(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaAwards(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaCompanyLogos(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaCompanyLogos(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaEvents(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaEvents(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaGenericPages(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaGenericPages(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaGenericTexts(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaGenericTexts(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaInsurances(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaInsurances(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaMicrosites(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaMicrosites(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaMrMoneyiFrames(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaMrMoneyiFrames(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNews(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaNews(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNewsletters(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaNewsletters(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNotes(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaNotes(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaTeamMembers(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaTeamMembers(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaVacancies(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaVacancies(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaWebinars(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllDegeniaWebinars(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmails(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllEmails(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNarkumaApplications(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getAllNarkumaApplications(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaArticle(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaArticle(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaAward(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaAward(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaCompanyLogo(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaCompanyLogo(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaEvent(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaEvent(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaGenericPage(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaGenericPage(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaGenericText(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaGenericText(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaInsurance(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaInsurance(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaLandingpage(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaLandingpage(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMicrosite(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaMicrosite(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMicrositeDescription(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaMicrositeDescription(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMrMoneyiFrame(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaMrMoneyiFrame(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNews(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaNews(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNewsletter(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaNewsletter(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNote(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaNote(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaTeamMember(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaTeamMember(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaVacancy(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaVacancy(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaWebinar(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getDegeniaWebinar(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmail(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getEmail(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNarkumaApplication(entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).getNarkumaApplication(entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaSearchConfigExcludeKeyofICmsDegeniaSearchConfigContentTypesToSearchIn} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInDegeniaHomepage(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).searchInDegeniaHomepage(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaSearchConfigExcludeKeyofICmsDegeniaSearchConfigContentTypesToSearchIn} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInMyDegenia(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).searchInMyDegenia(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsNarkumaSearchConfigExcludeKeyofICmsNarkumaSearchConfigContentTypesToSearchIn} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInNarkuma(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).searchInNarkuma(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeArticleExcludeKeyofICmsDegeniaContentTypeArticleIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaArticle(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaArticle(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaAward(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaAward(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaCompanyLogo(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaCompanyLogo(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeEventExcludeKeyofICmsDegeniaContentTypeEventIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaEvent(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaEvent(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericPageExcludeKeyofICmsDegeniaContentTypeGenericPageIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaGenericPage(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaGenericPage(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericTextExcludeKeyofICmsDegeniaContentTypeGenericTextIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaGenericText(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaGenericText(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeInsuranceExcludeKeyofICmsDegeniaContentTypeInsuranceIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaInsurance(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaInsurance(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeLandingpageExcludeKeyofICmsDegeniaContentTypeLandingpageIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaLandingpage(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaLandingpage(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeExcludeKeyofICmsDegeniaContentTypeMicrositeIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMicrosite(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaMicrosite(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeDescriptionExcludeKeyofICmsDegeniaContentTypeMicrositeDescriptionIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMicrositeDescription(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaMicrositeDescription(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMrMoneyiFrameExcludeKeyofICmsDegeniaContentTypeMrMoneyiFrameIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMrMoneyiFrame(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaMrMoneyiFrame(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeNewsletterExcludeKeyofICmsDegeniaContentTypeNewsletterIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaNewsletter(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaNewsletter(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeNoteExcludeKeyofICmsDegeniaContentTypeNoteIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaNote(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaNote(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeTeamMemberExcludeKeyofICmsDegeniaContentTypeTeamMemberIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaTeamMember(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaTeamMember(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeVacancyExcludeKeyofICmsDegeniaContentTypeVacancyIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaVacancy(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaVacancy(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeWebinarExcludeKeyofICmsDegeniaContentTypeWebinarIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaWebinar(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateDegeniaWebinar(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsContentTypeEmailExcludeKeyofICmsContentTypeEmailIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmail(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateEmail(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {PickICmsNarkumaContentTypeApplicationExcludeKeyofICmsNarkumaContentTypeApplicationIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNarkumaApplication(body, entryId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CMSApiAxiosParamCreator(configuration).updateNarkumaApplication(body, entryId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.CMSApiFp = CMSApiFp;
/**
 * CMSApi - factory interface
 * @export
 */
const CMSApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {PickICmsDegeniaContentTypeArticleExcludeKeyofICmsDegeniaContentTypeArticleIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaArticle(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaArticle(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaAward(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaAward(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaCompanyLogo(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaCompanyLogo(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeEventExcludeKeyofICmsDegeniaContentTypeEventIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaEvent(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaEvent(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericPageExcludeKeyofICmsDegeniaContentTypeGenericPageIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaGenericPage(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaGenericPage(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericTextExcludeKeyofICmsDegeniaContentTypeGenericTextIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaGenericText(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaGenericText(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeInsuranceExcludeKeyofICmsDegeniaContentTypeInsuranceIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaInsurance(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaInsurance(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeExcludeKeyofICmsDegeniaContentTypeMicrositeIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaMicrosite(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaMicrosite(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMrMoneyiFrameExcludeKeyofICmsDegeniaContentTypeMrMoneyiFrameIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaMrMoneyiFrame(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaMrMoneyiFrame(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeNewsletterExcludeKeyofICmsDegeniaContentTypeNewsletterIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaNewsletter(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaNewsletter(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeNoteExcludeKeyofICmsDegeniaContentTypeNoteIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaNote(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaNote(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeTeamMemberExcludeKeyofICmsDegeniaContentTypeTeamMemberIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaTeamMember(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaTeamMember(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeVacancyExcludeKeyofICmsDegeniaContentTypeVacancyIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaVacancy(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaVacancy(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeWebinarExcludeKeyofICmsDegeniaContentTypeWebinarIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDegeniaWebinar(body, options) {
            return exports.CMSApiFp(configuration).createDegeniaWebinar(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsContentTypeEmailExcludeKeyofICmsContentTypeEmailIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEmail(body, options) {
            return exports.CMSApiFp(configuration).createEmail(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsNarkumaContentTypeApplicationExcludeKeyofICmsNarkumaContentTypeApplicationIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNarkumaApplication(body, options) {
            return exports.CMSApiFp(configuration).createNarkumaApplication(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaArticles(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaArticles(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaAwards(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaAwards(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaCompanyLogos(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaCompanyLogos(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaEvents(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaEvents(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaGenericPages(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaGenericPages(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaGenericTexts(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaGenericTexts(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaInsurances(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaInsurances(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaMicrosites(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaMicrosites(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaMrMoneyiFrames(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaMrMoneyiFrames(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNews(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaNews(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNewsletters(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaNewsletters(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaNotes(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaNotes(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaTeamMembers(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaTeamMembers(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaVacancies(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaVacancies(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDegeniaWebinars(options) {
            return exports.CMSApiFp(configuration).getAllDegeniaWebinars(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEmails(options) {
            return exports.CMSApiFp(configuration).getAllEmails(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNarkumaApplications(options) {
            return exports.CMSApiFp(configuration).getAllNarkumaApplications(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaArticle(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaArticle(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaAward(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaAward(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaCompanyLogo(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaCompanyLogo(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaEvent(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaEvent(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaGenericPage(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaGenericPage(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaGenericText(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaGenericText(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaInsurance(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaInsurance(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaLandingpage(options) {
            return exports.CMSApiFp(configuration).getDegeniaLandingpage(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMicrosite(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaMicrosite(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMicrositeDescription(options) {
            return exports.CMSApiFp(configuration).getDegeniaMicrositeDescription(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaMrMoneyiFrame(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaMrMoneyiFrame(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNews(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaNews(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNewsletter(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaNewsletter(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaNote(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaNote(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaTeamMember(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaTeamMember(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaVacancy(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaVacancy(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDegeniaWebinar(entryId, options) {
            return exports.CMSApiFp(configuration).getDegeniaWebinar(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmail(entryId, options) {
            return exports.CMSApiFp(configuration).getEmail(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNarkumaApplication(entryId, options) {
            return exports.CMSApiFp(configuration).getNarkumaApplication(entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaSearchConfigExcludeKeyofICmsDegeniaSearchConfigContentTypesToSearchIn} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInDegeniaHomepage(body, options) {
            return exports.CMSApiFp(configuration).searchInDegeniaHomepage(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaSearchConfigExcludeKeyofICmsDegeniaSearchConfigContentTypesToSearchIn} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInMyDegenia(body, options) {
            return exports.CMSApiFp(configuration).searchInMyDegenia(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsNarkumaSearchConfigExcludeKeyofICmsNarkumaSearchConfigContentTypesToSearchIn} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchInNarkuma(body, options) {
            return exports.CMSApiFp(configuration).searchInNarkuma(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeArticleExcludeKeyofICmsDegeniaContentTypeArticleIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaArticle(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaArticle(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaAward(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaAward(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaCompanyLogo(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaCompanyLogo(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeEventExcludeKeyofICmsDegeniaContentTypeEventIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaEvent(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaEvent(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericPageExcludeKeyofICmsDegeniaContentTypeGenericPageIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaGenericPage(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaGenericPage(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeGenericTextExcludeKeyofICmsDegeniaContentTypeGenericTextIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaGenericText(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaGenericText(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeInsuranceExcludeKeyofICmsDegeniaContentTypeInsuranceIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaInsurance(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaInsurance(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeLandingpageExcludeKeyofICmsDegeniaContentTypeLandingpageIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaLandingpage(body, options) {
            return exports.CMSApiFp(configuration).updateDegeniaLandingpage(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeExcludeKeyofICmsDegeniaContentTypeMicrositeIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMicrosite(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaMicrosite(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMicrositeDescriptionExcludeKeyofICmsDegeniaContentTypeMicrositeDescriptionIdOrCreatedAtOrUpdatedAt} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMicrositeDescription(body, options) {
            return exports.CMSApiFp(configuration).updateDegeniaMicrositeDescription(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeMrMoneyiFrameExcludeKeyofICmsDegeniaContentTypeMrMoneyiFrameIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaMrMoneyiFrame(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaMrMoneyiFrame(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeNewsletterExcludeKeyofICmsDegeniaContentTypeNewsletterIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaNewsletter(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaNewsletter(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeNoteExcludeKeyofICmsDegeniaContentTypeNoteIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaNote(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaNote(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeTeamMemberExcludeKeyofICmsDegeniaContentTypeTeamMemberIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaTeamMember(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaTeamMember(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeVacancyExcludeKeyofICmsDegeniaContentTypeVacancyIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaVacancy(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaVacancy(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsDegeniaContentTypeWebinarExcludeKeyofICmsDegeniaContentTypeWebinarIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDegeniaWebinar(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateDegeniaWebinar(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsContentTypeEmailExcludeKeyofICmsContentTypeEmailIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEmail(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateEmail(body, entryId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {PickICmsNarkumaContentTypeApplicationExcludeKeyofICmsNarkumaContentTypeApplicationIdOrCreatedAtOrUpdatedAt} body
         * @param {string} entryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNarkumaApplication(body, entryId, options) {
            return exports.CMSApiFp(configuration).updateNarkumaApplication(body, entryId, options).then((request) => request(axios, basePath));
        },
    };
};
exports.CMSApiFactory = CMSApiFactory;
/**
 * CMSApi - object-oriented interface
 * @export
 * @class CMSApi
 * @extends {BaseAPI}
 */
class CMSApi extends base_1.BaseAPI {
    /**
     *
     * @param {PickICmsDegeniaContentTypeArticleExcludeKeyofICmsDegeniaContentTypeArticleIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaArticle(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaArticle(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaAward(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaAward(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaCompanyLogo(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaCompanyLogo(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeEventExcludeKeyofICmsDegeniaContentTypeEventIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaEvent(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaEvent(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeGenericPageExcludeKeyofICmsDegeniaContentTypeGenericPageIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaGenericPage(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaGenericPage(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeGenericTextExcludeKeyofICmsDegeniaContentTypeGenericTextIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaGenericText(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaGenericText(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeInsuranceExcludeKeyofICmsDegeniaContentTypeInsuranceIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaInsurance(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaInsurance(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeMicrositeExcludeKeyofICmsDegeniaContentTypeMicrositeIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaMicrosite(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaMicrosite(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeMrMoneyiFrameExcludeKeyofICmsDegeniaContentTypeMrMoneyiFrameIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaMrMoneyiFrame(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaMrMoneyiFrame(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeNewsletterExcludeKeyofICmsDegeniaContentTypeNewsletterIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaNewsletter(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaNewsletter(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeNoteExcludeKeyofICmsDegeniaContentTypeNoteIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaNote(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaNote(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeTeamMemberExcludeKeyofICmsDegeniaContentTypeTeamMemberIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaTeamMember(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaTeamMember(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeVacancyExcludeKeyofICmsDegeniaContentTypeVacancyIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaVacancy(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaVacancy(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeWebinarExcludeKeyofICmsDegeniaContentTypeWebinarIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createDegeniaWebinar(body, options) {
        return exports.CMSApiFp(this.configuration).createDegeniaWebinar(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsContentTypeEmailExcludeKeyofICmsContentTypeEmailIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createEmail(body, options) {
        return exports.CMSApiFp(this.configuration).createEmail(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsNarkumaContentTypeApplicationExcludeKeyofICmsNarkumaContentTypeApplicationIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    createNarkumaApplication(body, options) {
        return exports.CMSApiFp(this.configuration).createNarkumaApplication(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaArticles(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaArticles(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaAwards(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaAwards(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaCompanyLogos(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaCompanyLogos(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaEvents(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaEvents(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaGenericPages(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaGenericPages(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaGenericTexts(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaGenericTexts(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaInsurances(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaInsurances(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaMicrosites(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaMicrosites(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaMrMoneyiFrames(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaMrMoneyiFrames(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaNews(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaNews(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaNewsletters(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaNewsletters(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaNotes(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaNotes(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaTeamMembers(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaTeamMembers(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaVacancies(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaVacancies(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllDegeniaWebinars(options) {
        return exports.CMSApiFp(this.configuration).getAllDegeniaWebinars(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllEmails(options) {
        return exports.CMSApiFp(this.configuration).getAllEmails(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getAllNarkumaApplications(options) {
        return exports.CMSApiFp(this.configuration).getAllNarkumaApplications(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaArticle(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaArticle(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaAward(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaAward(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaCompanyLogo(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaCompanyLogo(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaEvent(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaEvent(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaGenericPage(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaGenericPage(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaGenericText(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaGenericText(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaInsurance(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaInsurance(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaLandingpage(options) {
        return exports.CMSApiFp(this.configuration).getDegeniaLandingpage(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaMicrosite(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaMicrosite(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaMicrositeDescription(options) {
        return exports.CMSApiFp(this.configuration).getDegeniaMicrositeDescription(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaMrMoneyiFrame(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaMrMoneyiFrame(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaNews(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaNews(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaNewsletter(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaNewsletter(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaNote(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaNote(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaTeamMember(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaTeamMember(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaVacancy(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaVacancy(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getDegeniaWebinar(entryId, options) {
        return exports.CMSApiFp(this.configuration).getDegeniaWebinar(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getEmail(entryId, options) {
        return exports.CMSApiFp(this.configuration).getEmail(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    getNarkumaApplication(entryId, options) {
        return exports.CMSApiFp(this.configuration).getNarkumaApplication(entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaSearchConfigExcludeKeyofICmsDegeniaSearchConfigContentTypesToSearchIn} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    searchInDegeniaHomepage(body, options) {
        return exports.CMSApiFp(this.configuration).searchInDegeniaHomepage(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaSearchConfigExcludeKeyofICmsDegeniaSearchConfigContentTypesToSearchIn} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    searchInMyDegenia(body, options) {
        return exports.CMSApiFp(this.configuration).searchInMyDegenia(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsNarkumaSearchConfigExcludeKeyofICmsNarkumaSearchConfigContentTypesToSearchIn} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    searchInNarkuma(body, options) {
        return exports.CMSApiFp(this.configuration).searchInNarkuma(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeArticleExcludeKeyofICmsDegeniaContentTypeArticleIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaArticle(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaArticle(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaAward(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaAward(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeAwardOrCompanyLogoExcludeKeyofICmsDegeniaContentTypeAwardOrCompanyLogoIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaCompanyLogo(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaCompanyLogo(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeEventExcludeKeyofICmsDegeniaContentTypeEventIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaEvent(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaEvent(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeGenericPageExcludeKeyofICmsDegeniaContentTypeGenericPageIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaGenericPage(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaGenericPage(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeGenericTextExcludeKeyofICmsDegeniaContentTypeGenericTextIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaGenericText(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaGenericText(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeInsuranceExcludeKeyofICmsDegeniaContentTypeInsuranceIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaInsurance(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaInsurance(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeLandingpageExcludeKeyofICmsDegeniaContentTypeLandingpageIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaLandingpage(body, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaLandingpage(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeMicrositeExcludeKeyofICmsDegeniaContentTypeMicrositeIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaMicrosite(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaMicrosite(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeMicrositeDescriptionExcludeKeyofICmsDegeniaContentTypeMicrositeDescriptionIdOrCreatedAtOrUpdatedAt} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaMicrositeDescription(body, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaMicrositeDescription(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeMrMoneyiFrameExcludeKeyofICmsDegeniaContentTypeMrMoneyiFrameIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaMrMoneyiFrame(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaMrMoneyiFrame(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeNewsletterExcludeKeyofICmsDegeniaContentTypeNewsletterIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaNewsletter(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaNewsletter(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeNoteExcludeKeyofICmsDegeniaContentTypeNoteIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaNote(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaNote(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeTeamMemberExcludeKeyofICmsDegeniaContentTypeTeamMemberIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaTeamMember(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaTeamMember(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeVacancyExcludeKeyofICmsDegeniaContentTypeVacancyIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaVacancy(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaVacancy(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsDegeniaContentTypeWebinarExcludeKeyofICmsDegeniaContentTypeWebinarIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateDegeniaWebinar(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateDegeniaWebinar(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsContentTypeEmailExcludeKeyofICmsContentTypeEmailIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateEmail(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateEmail(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {PickICmsNarkumaContentTypeApplicationExcludeKeyofICmsNarkumaContentTypeApplicationIdOrCreatedAtOrUpdatedAt} body
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CMSApi
     */
    updateNarkumaApplication(body, entryId, options) {
        return exports.CMSApiFp(this.configuration).updateNarkumaApplication(body, entryId, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.CMSApi = CMSApi;
/**
 * CalendarApi - axios parameter creator
 * @export
 */
const CalendarApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {ICalendarEventCreate} iCalendarEventCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewEvent: (iCalendarEventCreate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iCalendarEventCreate' is not null or undefined
            if (iCalendarEventCreate === null || iCalendarEventCreate === undefined) {
                throw new base_1.RequiredError('iCalendarEventCreate', 'Required parameter iCalendarEventCreate was null or undefined when calling createNewEvent.');
            }
            const localVarPath = `/calendar/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iCalendarEventCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iCalendarEventCreate !== undefined ? iCalendarEventCreate : {}) : (iCalendarEventCreate || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent: (eventId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new base_1.RequiredError('eventId', 'Required parameter eventId was null or undefined when calling deleteEvent.');
            }
            const localVarPath = `/calendar/events/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarsByUserId: (userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling getCalendarsByUserId.');
            }
            const localVarPath = `/calendar/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventDetails: (eventId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new base_1.RequiredError('eventId', 'Required parameter eventId was null or undefined when calling getEventDetails.');
            }
            const localVarPath = `/calendar/events/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ICalendarEventCreate} iCalendarEventCreate
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent: (iCalendarEventCreate, eventId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iCalendarEventCreate' is not null or undefined
            if (iCalendarEventCreate === null || iCalendarEventCreate === undefined) {
                throw new base_1.RequiredError('iCalendarEventCreate', 'Required parameter iCalendarEventCreate was null or undefined when calling updateEvent.');
            }
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new base_1.RequiredError('eventId', 'Required parameter eventId was null or undefined when calling updateEvent.');
            }
            const localVarPath = `/calendar/events/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iCalendarEventCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iCalendarEventCreate !== undefined ? iCalendarEventCreate : {}) : (iCalendarEventCreate || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.CalendarApiAxiosParamCreator = CalendarApiAxiosParamCreator;
/**
 * CalendarApi - functional programming interface
 * @export
 */
const CalendarApiFp = function (configuration) {
    return {
        /**
         *
         * @param {ICalendarEventCreate} iCalendarEventCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewEvent(iCalendarEventCreate, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CalendarApiAxiosParamCreator(configuration).createNewEvent(iCalendarEventCreate, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent(eventId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CalendarApiAxiosParamCreator(configuration).deleteEvent(eventId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarsByUserId(userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CalendarApiAxiosParamCreator(configuration).getCalendarsByUserId(userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventDetails(eventId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CalendarApiAxiosParamCreator(configuration).getEventDetails(eventId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {ICalendarEventCreate} iCalendarEventCreate
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent(iCalendarEventCreate, eventId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CalendarApiAxiosParamCreator(configuration).updateEvent(iCalendarEventCreate, eventId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.CalendarApiFp = CalendarApiFp;
/**
 * CalendarApi - factory interface
 * @export
 */
const CalendarApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {ICalendarEventCreate} iCalendarEventCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewEvent(iCalendarEventCreate, options) {
            return exports.CalendarApiFp(configuration).createNewEvent(iCalendarEventCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvent(eventId, options) {
            return exports.CalendarApiFp(configuration).deleteEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarsByUserId(userId, options) {
            return exports.CalendarApiFp(configuration).getCalendarsByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventDetails(eventId, options) {
            return exports.CalendarApiFp(configuration).getEventDetails(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ICalendarEventCreate} iCalendarEventCreate
         * @param {string} eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvent(iCalendarEventCreate, eventId, options) {
            return exports.CalendarApiFp(configuration).updateEvent(iCalendarEventCreate, eventId, options).then((request) => request(axios, basePath));
        },
    };
};
exports.CalendarApiFactory = CalendarApiFactory;
/**
 * CalendarApi - object-oriented interface
 * @export
 * @class CalendarApi
 * @extends {BaseAPI}
 */
class CalendarApi extends base_1.BaseAPI {
    /**
     *
     * @param {ICalendarEventCreate} iCalendarEventCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    createNewEvent(iCalendarEventCreate, options) {
        return exports.CalendarApiFp(this.configuration).createNewEvent(iCalendarEventCreate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    deleteEvent(eventId, options) {
        return exports.CalendarApiFp(this.configuration).deleteEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    getCalendarsByUserId(userId, options) {
        return exports.CalendarApiFp(this.configuration).getCalendarsByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    getEventDetails(eventId, options) {
        return exports.CalendarApiFp(this.configuration).getEventDetails(eventId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ICalendarEventCreate} iCalendarEventCreate
     * @param {string} eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    updateEvent(iCalendarEventCreate, eventId, options) {
        return exports.CalendarApiFp(this.configuration).updateEvent(iCalendarEventCreate, eventId, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.CalendarApi = CalendarApi;
/**
 * CommunicationApi - axios parameter creator
 * @export
 */
const CommunicationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} recipient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChat: (recipient, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'recipient' is not null or undefined
            if (recipient === null || recipient === undefined) {
                throw new base_1.RequiredError('recipient', 'Required parameter recipient was null or undefined when calling createChat.');
            }
            const localVarPath = `/communication/create-chat/{recipient}`
                .replace(`{${"recipient"}}`, encodeURIComponent(String(recipient)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatsOverview: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/communication/overview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ICommunicationGetMessagesOfChat} iCommunicationGetMessagesOfChat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesOfChat: (iCommunicationGetMessagesOfChat, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iCommunicationGetMessagesOfChat' is not null or undefined
            if (iCommunicationGetMessagesOfChat === null || iCommunicationGetMessagesOfChat === undefined) {
                throw new base_1.RequiredError('iCommunicationGetMessagesOfChat', 'Required parameter iCommunicationGetMessagesOfChat was null or undefined when calling getMessagesOfChat.');
            }
            const localVarPath = `/communication/get-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iCommunicationGetMessagesOfChat !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iCommunicationGetMessagesOfChat !== undefined ? iCommunicationGetMessagesOfChat : {}) : (iCommunicationGetMessagesOfChat || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ISendMessage} iSendMessage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage: (iSendMessage, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iSendMessage' is not null or undefined
            if (iSendMessage === null || iSendMessage === undefined) {
                throw new base_1.RequiredError('iSendMessage', 'Required parameter iSendMessage was null or undefined when calling sendMessage.');
            }
            const localVarPath = `/communication/send-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iSendMessage !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iSendMessage !== undefined ? iSendMessage : {}) : (iSendMessage || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.CommunicationApiAxiosParamCreator = CommunicationApiAxiosParamCreator;
/**
 * CommunicationApi - functional programming interface
 * @export
 */
const CommunicationApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} recipient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChat(recipient, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CommunicationApiAxiosParamCreator(configuration).createChat(recipient, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatsOverview(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CommunicationApiAxiosParamCreator(configuration).getChatsOverview(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {ICommunicationGetMessagesOfChat} iCommunicationGetMessagesOfChat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesOfChat(iCommunicationGetMessagesOfChat, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CommunicationApiAxiosParamCreator(configuration).getMessagesOfChat(iCommunicationGetMessagesOfChat, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {ISendMessage} iSendMessage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage(iSendMessage, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CommunicationApiAxiosParamCreator(configuration).sendMessage(iSendMessage, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.CommunicationApiFp = CommunicationApiFp;
/**
 * CommunicationApi - factory interface
 * @export
 */
const CommunicationApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} recipient
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChat(recipient, options) {
            return exports.CommunicationApiFp(configuration).createChat(recipient, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatsOverview(options) {
            return exports.CommunicationApiFp(configuration).getChatsOverview(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ICommunicationGetMessagesOfChat} iCommunicationGetMessagesOfChat
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesOfChat(iCommunicationGetMessagesOfChat, options) {
            return exports.CommunicationApiFp(configuration).getMessagesOfChat(iCommunicationGetMessagesOfChat, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ISendMessage} iSendMessage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage(iSendMessage, options) {
            return exports.CommunicationApiFp(configuration).sendMessage(iSendMessage, options).then((request) => request(axios, basePath));
        },
    };
};
exports.CommunicationApiFactory = CommunicationApiFactory;
/**
 * CommunicationApi - object-oriented interface
 * @export
 * @class CommunicationApi
 * @extends {BaseAPI}
 */
class CommunicationApi extends base_1.BaseAPI {
    /**
     *
     * @param {string} recipient
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationApi
     */
    createChat(recipient, options) {
        return exports.CommunicationApiFp(this.configuration).createChat(recipient, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationApi
     */
    getChatsOverview(options) {
        return exports.CommunicationApiFp(this.configuration).getChatsOverview(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ICommunicationGetMessagesOfChat} iCommunicationGetMessagesOfChat
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationApi
     */
    getMessagesOfChat(iCommunicationGetMessagesOfChat, options) {
        return exports.CommunicationApiFp(this.configuration).getMessagesOfChat(iCommunicationGetMessagesOfChat, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ISendMessage} iSendMessage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunicationApi
     */
    sendMessage(iSendMessage, options) {
        return exports.CommunicationApiFp(this.configuration).sendMessage(iSendMessage, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.CommunicationApi = CommunicationApi;
/**
 * CreditsApi - axios parameter creator
 * @export
 */
const CreditsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransaction: (transactionId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'transactionId' is not null or undefined
            if (transactionId === null || transactionId === undefined) {
                throw new base_1.RequiredError('transactionId', 'Required parameter transactionId was null or undefined when calling cancelTransaction.');
            }
            const localVarPath = `/credits/transactions/cancel/{transactionId}`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ICreditsCreditOfferingCreate} iCreditsCreditOfferingCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCreditOffering: (iCreditsCreditOfferingCreate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iCreditsCreditOfferingCreate' is not null or undefined
            if (iCreditsCreditOfferingCreate === null || iCreditsCreditOfferingCreate === undefined) {
                throw new base_1.RequiredError('iCreditsCreditOfferingCreate', 'Required parameter iCreditsCreditOfferingCreate was null or undefined when calling createCreditOffering.');
            }
            const localVarPath = `/credits/offerings/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iCreditsCreditOfferingCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iCreditsCreditOfferingCreate !== undefined ? iCreditsCreditOfferingCreate : {}) : (iCreditsCreditOfferingCreate || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ICreditsCreditTransactionCreate} iCreditsCreditTransactionCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransaction: (iCreditsCreditTransactionCreate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iCreditsCreditTransactionCreate' is not null or undefined
            if (iCreditsCreditTransactionCreate === null || iCreditsCreditTransactionCreate === undefined) {
                throw new base_1.RequiredError('iCreditsCreditTransactionCreate', 'Required parameter iCreditsCreditTransactionCreate was null or undefined when calling createTransaction.');
            }
            const localVarPath = `/credits/transactions/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iCreditsCreditTransactionCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iCreditsCreditTransactionCreate !== undefined ? iCreditsCreditTransactionCreate : {}) : (iCreditsCreditTransactionCreate || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveCreditOfferings: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/credits/offerings/get/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCreditOfferings: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/credits/offerings/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArchivedCreditOfferings: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/credits/offerings/get/archived`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditOfferingById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new base_1.RequiredError('id', 'Required parameter id was null or undefined when calling getCreditOfferingById.');
            }
            const localVarPath = `/credits/offerings/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBalance: (userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling getUserBalance.');
            }
            const localVarPath = `/credits/balance/getByUser/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTransactions: (userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling getUserTransactions.');
            }
            const localVarPath = `/credits/purchase/getByUser/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ICreditsCreditPurchaseIntent} iCreditsCreditPurchaseIntent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseCredits: (iCreditsCreditPurchaseIntent, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iCreditsCreditPurchaseIntent' is not null or undefined
            if (iCreditsCreditPurchaseIntent === null || iCreditsCreditPurchaseIntent === undefined) {
                throw new base_1.RequiredError('iCreditsCreditPurchaseIntent', 'Required parameter iCreditsCreditPurchaseIntent was null or undefined when calling purchaseCredits.');
            }
            const localVarPath = `/credits/purchase/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iCreditsCreditPurchaseIntent !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iCreditsCreditPurchaseIntent !== undefined ? iCreditsCreditPurchaseIntent : {}) : (iCreditsCreditPurchaseIntent || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ICreditsCreditSpendIntent} iCreditsCreditSpendIntent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendCredits: (iCreditsCreditSpendIntent, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iCreditsCreditSpendIntent' is not null or undefined
            if (iCreditsCreditSpendIntent === null || iCreditsCreditSpendIntent === undefined) {
                throw new base_1.RequiredError('iCreditsCreditSpendIntent', 'Required parameter iCreditsCreditSpendIntent was null or undefined when calling spendCredits.');
            }
            const localVarPath = `/credits/spend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iCreditsCreditSpendIntent !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iCreditsCreditSpendIntent !== undefined ? iCreditsCreditSpendIntent : {}) : (iCreditsCreditSpendIntent || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.CreditsApiAxiosParamCreator = CreditsApiAxiosParamCreator;
/**
 * CreditsApi - functional programming interface
 * @export
 */
const CreditsApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransaction(transactionId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CreditsApiAxiosParamCreator(configuration).cancelTransaction(transactionId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {ICreditsCreditOfferingCreate} iCreditsCreditOfferingCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCreditOffering(iCreditsCreditOfferingCreate, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CreditsApiAxiosParamCreator(configuration).createCreditOffering(iCreditsCreditOfferingCreate, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {ICreditsCreditTransactionCreate} iCreditsCreditTransactionCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransaction(iCreditsCreditTransactionCreate, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CreditsApiAxiosParamCreator(configuration).createTransaction(iCreditsCreditTransactionCreate, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveCreditOfferings(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CreditsApiAxiosParamCreator(configuration).getActiveCreditOfferings(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCreditOfferings(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CreditsApiAxiosParamCreator(configuration).getAllCreditOfferings(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArchivedCreditOfferings(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CreditsApiAxiosParamCreator(configuration).getArchivedCreditOfferings(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditOfferingById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CreditsApiAxiosParamCreator(configuration).getCreditOfferingById(id, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBalance(userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CreditsApiAxiosParamCreator(configuration).getUserBalance(userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTransactions(userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CreditsApiAxiosParamCreator(configuration).getUserTransactions(userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {ICreditsCreditPurchaseIntent} iCreditsCreditPurchaseIntent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseCredits(iCreditsCreditPurchaseIntent, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CreditsApiAxiosParamCreator(configuration).purchaseCredits(iCreditsCreditPurchaseIntent, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {ICreditsCreditSpendIntent} iCreditsCreditSpendIntent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendCredits(iCreditsCreditSpendIntent, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CreditsApiAxiosParamCreator(configuration).spendCredits(iCreditsCreditSpendIntent, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.CreditsApiFp = CreditsApiFp;
/**
 * CreditsApi - factory interface
 * @export
 */
const CreditsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTransaction(transactionId, options) {
            return exports.CreditsApiFp(configuration).cancelTransaction(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ICreditsCreditOfferingCreate} iCreditsCreditOfferingCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCreditOffering(iCreditsCreditOfferingCreate, options) {
            return exports.CreditsApiFp(configuration).createCreditOffering(iCreditsCreditOfferingCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ICreditsCreditTransactionCreate} iCreditsCreditTransactionCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTransaction(iCreditsCreditTransactionCreate, options) {
            return exports.CreditsApiFp(configuration).createTransaction(iCreditsCreditTransactionCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveCreditOfferings(options) {
            return exports.CreditsApiFp(configuration).getActiveCreditOfferings(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCreditOfferings(options) {
            return exports.CreditsApiFp(configuration).getAllCreditOfferings(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArchivedCreditOfferings(options) {
            return exports.CreditsApiFp(configuration).getArchivedCreditOfferings(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreditOfferingById(id, options) {
            return exports.CreditsApiFp(configuration).getCreditOfferingById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBalance(userId, options) {
            return exports.CreditsApiFp(configuration).getUserBalance(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTransactions(userId, options) {
            return exports.CreditsApiFp(configuration).getUserTransactions(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ICreditsCreditPurchaseIntent} iCreditsCreditPurchaseIntent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseCredits(iCreditsCreditPurchaseIntent, options) {
            return exports.CreditsApiFp(configuration).purchaseCredits(iCreditsCreditPurchaseIntent, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ICreditsCreditSpendIntent} iCreditsCreditSpendIntent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spendCredits(iCreditsCreditSpendIntent, options) {
            return exports.CreditsApiFp(configuration).spendCredits(iCreditsCreditSpendIntent, options).then((request) => request(axios, basePath));
        },
    };
};
exports.CreditsApiFactory = CreditsApiFactory;
/**
 * CreditsApi - object-oriented interface
 * @export
 * @class CreditsApi
 * @extends {BaseAPI}
 */
class CreditsApi extends base_1.BaseAPI {
    /**
     *
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditsApi
     */
    cancelTransaction(transactionId, options) {
        return exports.CreditsApiFp(this.configuration).cancelTransaction(transactionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ICreditsCreditOfferingCreate} iCreditsCreditOfferingCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditsApi
     */
    createCreditOffering(iCreditsCreditOfferingCreate, options) {
        return exports.CreditsApiFp(this.configuration).createCreditOffering(iCreditsCreditOfferingCreate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ICreditsCreditTransactionCreate} iCreditsCreditTransactionCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditsApi
     */
    createTransaction(iCreditsCreditTransactionCreate, options) {
        return exports.CreditsApiFp(this.configuration).createTransaction(iCreditsCreditTransactionCreate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditsApi
     */
    getActiveCreditOfferings(options) {
        return exports.CreditsApiFp(this.configuration).getActiveCreditOfferings(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditsApi
     */
    getAllCreditOfferings(options) {
        return exports.CreditsApiFp(this.configuration).getAllCreditOfferings(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditsApi
     */
    getArchivedCreditOfferings(options) {
        return exports.CreditsApiFp(this.configuration).getArchivedCreditOfferings(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditsApi
     */
    getCreditOfferingById(id, options) {
        return exports.CreditsApiFp(this.configuration).getCreditOfferingById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditsApi
     */
    getUserBalance(userId, options) {
        return exports.CreditsApiFp(this.configuration).getUserBalance(userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditsApi
     */
    getUserTransactions(userId, options) {
        return exports.CreditsApiFp(this.configuration).getUserTransactions(userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ICreditsCreditPurchaseIntent} iCreditsCreditPurchaseIntent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditsApi
     */
    purchaseCredits(iCreditsCreditPurchaseIntent, options) {
        return exports.CreditsApiFp(this.configuration).purchaseCredits(iCreditsCreditPurchaseIntent, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ICreditsCreditSpendIntent} iCreditsCreditSpendIntent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditsApi
     */
    spendCredits(iCreditsCreditSpendIntent, options) {
        return exports.CreditsApiFp(this.configuration).spendCredits(iCreditsCreditSpendIntent, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.CreditsApi = CreditsApi;
/**
 * DebugApi - axios parameter creator
 * @export
 */
const DebugApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserToken: (userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling createUserToken.');
            }
            const localVarPath = `/debug/create-user-token/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flushDatabases: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/debug/flush/databases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {any} body
         * @param {string} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerSubscription: (body, type, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new base_1.RequiredError('body', 'Required parameter body was null or undefined when calling triggerSubscription.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new base_1.RequiredError('type', 'Required parameter type was null or undefined when calling triggerSubscription.');
            }
            const localVarPath = `/debug/trigger-subscription/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.DebugApiAxiosParamCreator = DebugApiAxiosParamCreator;
/**
 * DebugApi - functional programming interface
 * @export
 */
const DebugApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserToken(userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DebugApiAxiosParamCreator(configuration).createUserToken(userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flushDatabases(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DebugApiAxiosParamCreator(configuration).flushDatabases(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {any} body
         * @param {string} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerSubscription(body, type, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DebugApiAxiosParamCreator(configuration).triggerSubscription(body, type, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.DebugApiFp = DebugApiFp;
/**
 * DebugApi - factory interface
 * @export
 */
const DebugApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserToken(userId, options) {
            return exports.DebugApiFp(configuration).createUserToken(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        flushDatabases(options) {
            return exports.DebugApiFp(configuration).flushDatabases(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {any} body
         * @param {string} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerSubscription(body, type, options) {
            return exports.DebugApiFp(configuration).triggerSubscription(body, type, options).then((request) => request(axios, basePath));
        },
    };
};
exports.DebugApiFactory = DebugApiFactory;
/**
 * DebugApi - object-oriented interface
 * @export
 * @class DebugApi
 * @extends {BaseAPI}
 */
class DebugApi extends base_1.BaseAPI {
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugApi
     */
    createUserToken(userId, options) {
        return exports.DebugApiFp(this.configuration).createUserToken(userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugApi
     */
    flushDatabases(options) {
        return exports.DebugApiFp(this.configuration).flushDatabases(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {any} body
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DebugApi
     */
    triggerSubscription(body, type, options) {
        return exports.DebugApiFp(this.configuration).triggerSubscription(body, type, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.DebugApi = DebugApi;
/**
 * FileApi - axios parameter creator
 * @export
 */
const FileApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} userId
         * @param {string} path
         * @param {boolean} isPublic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFile: (userId, path, isPublic, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling getUserFile.');
            }
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new base_1.RequiredError('path', 'Required parameter path was null or undefined when calling getUserFile.');
            }
            // verify required parameter 'isPublic' is not null or undefined
            if (isPublic === null || isPublic === undefined) {
                throw new base_1.RequiredError('isPublic', 'Required parameter isPublic was null or undefined when calling getUserFile.');
            }
            const localVarPath = `/file/{userId}/single/{path}/{isPublic}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)))
                .replace(`{${"isPublic"}}`, encodeURIComponent(String(isPublic)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} bucketName
         * @param {string} directory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDirectory: (bucketName, directory, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'bucketName' is not null or undefined
            if (bucketName === null || bucketName === undefined) {
                throw new base_1.RequiredError('bucketName', 'Required parameter bucketName was null or undefined when calling removeDirectory.');
            }
            // verify required parameter 'directory' is not null or undefined
            if (directory === null || directory === undefined) {
                throw new base_1.RequiredError('directory', 'Required parameter directory was null or undefined when calling removeDirectory.');
            }
            const localVarPath = `/file/remove-directory/{bucketName}/{directory}`
                .replace(`{${"bucketName"}}`, encodeURIComponent(String(bucketName)))
                .replace(`{${"directory"}}`, encodeURIComponent(String(directory)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} bucketName
         * @param {string} filePath
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFile: (bucketName, filePath, userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'bucketName' is not null or undefined
            if (bucketName === null || bucketName === undefined) {
                throw new base_1.RequiredError('bucketName', 'Required parameter bucketName was null or undefined when calling removeFile.');
            }
            // verify required parameter 'filePath' is not null or undefined
            if (filePath === null || filePath === undefined) {
                throw new base_1.RequiredError('filePath', 'Required parameter filePath was null or undefined when calling removeFile.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling removeFile.');
            }
            const localVarPath = `/file/remove/{bucketName}/{userId}/{filePath}`
                .replace(`{${"bucketName"}}`, encodeURIComponent(String(bucketName)))
                .replace(`{${"filePath"}}`, encodeURIComponent(String(filePath)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} userId
         * @param {string} path
         * @param {boolean} isPublic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUserFiles: (userId, path, isPublic, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling showUserFiles.');
            }
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new base_1.RequiredError('path', 'Required parameter path was null or undefined when calling showUserFiles.');
            }
            // verify required parameter 'isPublic' is not null or undefined
            if (isPublic === null || isPublic === undefined) {
                throw new base_1.RequiredError('isPublic', 'Required parameter isPublic was null or undefined when calling showUserFiles.');
            }
            const localVarPath = `/file/{userId}/{path}/{isPublic}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)))
                .replace(`{${"isPublic"}}`, encodeURIComponent(String(isPublic)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} userId
         * @param {string} path
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPrivateUpload: (userId, path, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling userPrivateUpload.');
            }
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new base_1.RequiredError('path', 'Required parameter path was null or undefined when calling userPrivateUpload.');
            }
            const localVarPath = `/file/upload/{userId}/{path}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} userId
         * @param {string} path
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPublicImageUpload: (userId, path, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling userPublicImageUpload.');
            }
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new base_1.RequiredError('path', 'Required parameter path was null or undefined when calling userPublicImageUpload.');
            }
            const localVarPath = `/file/public-image-upload/{userId}/{path}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} userId
         * @param {string} path
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPublicUpload: (userId, path, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling userPublicUpload.');
            }
            // verify required parameter 'path' is not null or undefined
            if (path === null || path === undefined) {
                throw new base_1.RequiredError('path', 'Required parameter path was null or undefined when calling userPublicUpload.');
            }
            const localVarPath = `/file/public-upload/{userId}/{path}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.FileApiAxiosParamCreator = FileApiAxiosParamCreator;
/**
 * FileApi - functional programming interface
 * @export
 */
const FileApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} userId
         * @param {string} path
         * @param {boolean} isPublic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFile(userId, path, isPublic, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.FileApiAxiosParamCreator(configuration).getUserFile(userId, path, isPublic, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} bucketName
         * @param {string} directory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDirectory(bucketName, directory, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.FileApiAxiosParamCreator(configuration).removeDirectory(bucketName, directory, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} bucketName
         * @param {string} filePath
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFile(bucketName, filePath, userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.FileApiAxiosParamCreator(configuration).removeFile(bucketName, filePath, userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} userId
         * @param {string} path
         * @param {boolean} isPublic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUserFiles(userId, path, isPublic, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.FileApiAxiosParamCreator(configuration).showUserFiles(userId, path, isPublic, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} userId
         * @param {string} path
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPrivateUpload(userId, path, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.FileApiAxiosParamCreator(configuration).userPrivateUpload(userId, path, file, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} userId
         * @param {string} path
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPublicImageUpload(userId, path, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.FileApiAxiosParamCreator(configuration).userPublicImageUpload(userId, path, file, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} userId
         * @param {string} path
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPublicUpload(userId, path, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.FileApiAxiosParamCreator(configuration).userPublicUpload(userId, path, file, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.FileApiFp = FileApiFp;
/**
 * FileApi - factory interface
 * @export
 */
const FileApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} userId
         * @param {string} path
         * @param {boolean} isPublic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFile(userId, path, isPublic, options) {
            return exports.FileApiFp(configuration).getUserFile(userId, path, isPublic, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} bucketName
         * @param {string} directory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDirectory(bucketName, directory, options) {
            return exports.FileApiFp(configuration).removeDirectory(bucketName, directory, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} bucketName
         * @param {string} filePath
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFile(bucketName, filePath, userId, options) {
            return exports.FileApiFp(configuration).removeFile(bucketName, filePath, userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {string} path
         * @param {boolean} isPublic
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUserFiles(userId, path, isPublic, options) {
            return exports.FileApiFp(configuration).showUserFiles(userId, path, isPublic, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {string} path
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPrivateUpload(userId, path, file, options) {
            return exports.FileApiFp(configuration).userPrivateUpload(userId, path, file, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {string} path
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPublicImageUpload(userId, path, file, options) {
            return exports.FileApiFp(configuration).userPublicImageUpload(userId, path, file, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {string} path
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPublicUpload(userId, path, file, options) {
            return exports.FileApiFp(configuration).userPublicUpload(userId, path, file, options).then((request) => request(axios, basePath));
        },
    };
};
exports.FileApiFactory = FileApiFactory;
/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
class FileApi extends base_1.BaseAPI {
    /**
     *
     * @param {string} userId
     * @param {string} path
     * @param {boolean} isPublic
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    getUserFile(userId, path, isPublic, options) {
        return exports.FileApiFp(this.configuration).getUserFile(userId, path, isPublic, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} bucketName
     * @param {string} directory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    removeDirectory(bucketName, directory, options) {
        return exports.FileApiFp(this.configuration).removeDirectory(bucketName, directory, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} bucketName
     * @param {string} filePath
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    removeFile(bucketName, filePath, userId, options) {
        return exports.FileApiFp(this.configuration).removeFile(bucketName, filePath, userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} userId
     * @param {string} path
     * @param {boolean} isPublic
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    showUserFiles(userId, path, isPublic, options) {
        return exports.FileApiFp(this.configuration).showUserFiles(userId, path, isPublic, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} userId
     * @param {string} path
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    userPrivateUpload(userId, path, file, options) {
        return exports.FileApiFp(this.configuration).userPrivateUpload(userId, path, file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} userId
     * @param {string} path
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    userPublicImageUpload(userId, path, file, options) {
        return exports.FileApiFp(this.configuration).userPublicImageUpload(userId, path, file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} userId
     * @param {string} path
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    userPublicUpload(userId, path, file, options) {
        return exports.FileApiFp(this.configuration).userPublicUpload(userId, path, file, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.FileApi = FileApi;
/**
 * MailApi - axios parameter creator
 * @export
 */
const MailApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {IMailSendAddressChange} iMailSendAddressChange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaAddressChange: (iMailSendAddressChange, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iMailSendAddressChange' is not null or undefined
            if (iMailSendAddressChange === null || iMailSendAddressChange === undefined) {
                throw new base_1.RequiredError('iMailSendAddressChange', 'Required parameter iMailSendAddressChange was null or undefined when calling sendDegeniaAddressChange.');
            }
            const localVarPath = `/mail/degenia/contract/sendAddressChange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iMailSendAddressChange !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iMailSendAddressChange !== undefined ? iMailSendAddressChange : {}) : (iMailSendAddressChange || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IMailSendBankChange} iMailSendBankChange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaBankChange: (iMailSendBankChange, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iMailSendBankChange' is not null or undefined
            if (iMailSendBankChange === null || iMailSendBankChange === undefined) {
                throw new base_1.RequiredError('iMailSendBankChange', 'Required parameter iMailSendBankChange was null or undefined when calling sendDegeniaBankChange.');
            }
            const localVarPath = `/mail/degenia/contract/sendBankChange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iMailSendBankChange !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iMailSendBankChange !== undefined ? iMailSendBankChange : {}) : (iMailSendBankChange || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IMailSendBecomeOurPartner} iMailSendBecomeOurPartner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaBecomeOurPartner: (iMailSendBecomeOurPartner, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iMailSendBecomeOurPartner' is not null or undefined
            if (iMailSendBecomeOurPartner === null || iMailSendBecomeOurPartner === undefined) {
                throw new base_1.RequiredError('iMailSendBecomeOurPartner', 'Required parameter iMailSendBecomeOurPartner was null or undefined when calling sendDegeniaBecomeOurPartner.');
            }
            const localVarPath = `/mail/degenia/sendBecomeOurPartner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iMailSendBecomeOurPartner !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iMailSendBecomeOurPartner !== undefined ? iMailSendBecomeOurPartner : {}) : (iMailSendBecomeOurPartner || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IMailSendDirectMessage} iMailSendDirectMessage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaDirectMessage: (iMailSendDirectMessage, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iMailSendDirectMessage' is not null or undefined
            if (iMailSendDirectMessage === null || iMailSendDirectMessage === undefined) {
                throw new base_1.RequiredError('iMailSendDirectMessage', 'Required parameter iMailSendDirectMessage was null or undefined when calling sendDegeniaDirectMessage.');
            }
            const localVarPath = `/mail/degenia/contract/sendDirectMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iMailSendDirectMessage !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iMailSendDirectMessage !== undefined ? iMailSendDirectMessage : {}) : (iMailSendDirectMessage || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IMailUserUserPasswordReset} iMailUserUserPasswordReset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordResetMail: (iMailUserUserPasswordReset, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iMailUserUserPasswordReset' is not null or undefined
            if (iMailUserUserPasswordReset === null || iMailUserUserPasswordReset === undefined) {
                throw new base_1.RequiredError('iMailUserUserPasswordReset', 'Required parameter iMailUserUserPasswordReset was null or undefined when calling sendPasswordResetMail.');
            }
            const localVarPath = `/mail/sendPasswordResetMail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iMailUserUserPasswordReset !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iMailUserUserPasswordReset !== undefined ? iMailUserUserPasswordReset : {}) : (iMailUserUserPasswordReset || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IMailSendStrapiMail} iMailSendStrapiMail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendStrapiMail: (iMailSendStrapiMail, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iMailSendStrapiMail' is not null or undefined
            if (iMailSendStrapiMail === null || iMailSendStrapiMail === undefined) {
                throw new base_1.RequiredError('iMailSendStrapiMail', 'Required parameter iMailSendStrapiMail was null or undefined when calling sendStrapiMail.');
            }
            const localVarPath = `/mail/sendStrapiMail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iMailSendStrapiMail !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iMailSendStrapiMail !== undefined ? iMailSendStrapiMail : {}) : (iMailSendStrapiMail || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.MailApiAxiosParamCreator = MailApiAxiosParamCreator;
/**
 * MailApi - functional programming interface
 * @export
 */
const MailApiFp = function (configuration) {
    return {
        /**
         *
         * @param {IMailSendAddressChange} iMailSendAddressChange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaAddressChange(iMailSendAddressChange, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.MailApiAxiosParamCreator(configuration).sendDegeniaAddressChange(iMailSendAddressChange, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IMailSendBankChange} iMailSendBankChange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaBankChange(iMailSendBankChange, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.MailApiAxiosParamCreator(configuration).sendDegeniaBankChange(iMailSendBankChange, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IMailSendBecomeOurPartner} iMailSendBecomeOurPartner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaBecomeOurPartner(iMailSendBecomeOurPartner, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.MailApiAxiosParamCreator(configuration).sendDegeniaBecomeOurPartner(iMailSendBecomeOurPartner, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IMailSendDirectMessage} iMailSendDirectMessage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaDirectMessage(iMailSendDirectMessage, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.MailApiAxiosParamCreator(configuration).sendDegeniaDirectMessage(iMailSendDirectMessage, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IMailUserUserPasswordReset} iMailUserUserPasswordReset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordResetMail(iMailUserUserPasswordReset, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.MailApiAxiosParamCreator(configuration).sendPasswordResetMail(iMailUserUserPasswordReset, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IMailSendStrapiMail} iMailSendStrapiMail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendStrapiMail(iMailSendStrapiMail, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.MailApiAxiosParamCreator(configuration).sendStrapiMail(iMailSendStrapiMail, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.MailApiFp = MailApiFp;
/**
 * MailApi - factory interface
 * @export
 */
const MailApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {IMailSendAddressChange} iMailSendAddressChange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaAddressChange(iMailSendAddressChange, options) {
            return exports.MailApiFp(configuration).sendDegeniaAddressChange(iMailSendAddressChange, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IMailSendBankChange} iMailSendBankChange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaBankChange(iMailSendBankChange, options) {
            return exports.MailApiFp(configuration).sendDegeniaBankChange(iMailSendBankChange, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IMailSendBecomeOurPartner} iMailSendBecomeOurPartner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaBecomeOurPartner(iMailSendBecomeOurPartner, options) {
            return exports.MailApiFp(configuration).sendDegeniaBecomeOurPartner(iMailSendBecomeOurPartner, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IMailSendDirectMessage} iMailSendDirectMessage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDegeniaDirectMessage(iMailSendDirectMessage, options) {
            return exports.MailApiFp(configuration).sendDegeniaDirectMessage(iMailSendDirectMessage, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IMailUserUserPasswordReset} iMailUserUserPasswordReset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPasswordResetMail(iMailUserUserPasswordReset, options) {
            return exports.MailApiFp(configuration).sendPasswordResetMail(iMailUserUserPasswordReset, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IMailSendStrapiMail} iMailSendStrapiMail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendStrapiMail(iMailSendStrapiMail, options) {
            return exports.MailApiFp(configuration).sendStrapiMail(iMailSendStrapiMail, options).then((request) => request(axios, basePath));
        },
    };
};
exports.MailApiFactory = MailApiFactory;
/**
 * MailApi - object-oriented interface
 * @export
 * @class MailApi
 * @extends {BaseAPI}
 */
class MailApi extends base_1.BaseAPI {
    /**
     *
     * @param {IMailSendAddressChange} iMailSendAddressChange
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    sendDegeniaAddressChange(iMailSendAddressChange, options) {
        return exports.MailApiFp(this.configuration).sendDegeniaAddressChange(iMailSendAddressChange, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IMailSendBankChange} iMailSendBankChange
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    sendDegeniaBankChange(iMailSendBankChange, options) {
        return exports.MailApiFp(this.configuration).sendDegeniaBankChange(iMailSendBankChange, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IMailSendBecomeOurPartner} iMailSendBecomeOurPartner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    sendDegeniaBecomeOurPartner(iMailSendBecomeOurPartner, options) {
        return exports.MailApiFp(this.configuration).sendDegeniaBecomeOurPartner(iMailSendBecomeOurPartner, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IMailSendDirectMessage} iMailSendDirectMessage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    sendDegeniaDirectMessage(iMailSendDirectMessage, options) {
        return exports.MailApiFp(this.configuration).sendDegeniaDirectMessage(iMailSendDirectMessage, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IMailUserUserPasswordReset} iMailUserUserPasswordReset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    sendPasswordResetMail(iMailUserUserPasswordReset, options) {
        return exports.MailApiFp(this.configuration).sendPasswordResetMail(iMailUserUserPasswordReset, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IMailSendStrapiMail} iMailSendStrapiMail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApi
     */
    sendStrapiMail(iMailSendStrapiMail, options) {
        return exports.MailApiFp(this.configuration).sendStrapiMail(iMailSendStrapiMail, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.MailApi = MailApi;
/**
 * PaymentApi - axios parameter creator
 * @export
 */
const PaymentApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveProduct: (productId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new base_1.RequiredError('productId', 'Required parameter productId was null or undefined when calling archiveProduct.');
            }
            const localVarPath = `/payment/products/archive/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} subscriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveSubscription: (subscriptionId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new base_1.RequiredError('subscriptionId', 'Required parameter subscriptionId was null or undefined when calling archiveSubscription.');
            }
            const localVarPath = `/payment/subscriptions/archive/{subscriptionId}`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IPaymentCustomerCreate} iPaymentCustomerCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer: (iPaymentCustomerCreate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iPaymentCustomerCreate' is not null or undefined
            if (iPaymentCustomerCreate === null || iPaymentCustomerCreate === undefined) {
                throw new base_1.RequiredError('iPaymentCustomerCreate', 'Required parameter iPaymentCustomerCreate was null or undefined when calling createCustomer.');
            }
            const localVarPath = `/payment/customers/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iPaymentCustomerCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iPaymentCustomerCreate !== undefined ? iPaymentCustomerCreate : {}) : (iPaymentCustomerCreate || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IPaymentProductCreate} iPaymentProductCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: (iPaymentProductCreate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iPaymentProductCreate' is not null or undefined
            if (iPaymentProductCreate === null || iPaymentProductCreate === undefined) {
                throw new base_1.RequiredError('iPaymentProductCreate', 'Required parameter iPaymentProductCreate was null or undefined when calling createProduct.');
            }
            const localVarPath = `/payment/products/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iPaymentProductCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iPaymentProductCreate !== undefined ? iPaymentProductCreate : {}) : (iPaymentProductCreate || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IPaymentProductCheckoutIntent} iPaymentProductCheckoutIntent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductCheckout: (iPaymentProductCheckoutIntent, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iPaymentProductCheckoutIntent' is not null or undefined
            if (iPaymentProductCheckoutIntent === null || iPaymentProductCheckoutIntent === undefined) {
                throw new base_1.RequiredError('iPaymentProductCheckoutIntent', 'Required parameter iPaymentProductCheckoutIntent was null or undefined when calling createProductCheckout.');
            }
            const localVarPath = `/payment/purchases/product/checkout/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iPaymentProductCheckoutIntent !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iPaymentProductCheckoutIntent !== undefined ? iPaymentProductCheckoutIntent : {}) : (iPaymentProductCheckoutIntent || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IPaymentSubscriptionCreate} iPaymentSubscriptionCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription: (iPaymentSubscriptionCreate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iPaymentSubscriptionCreate' is not null or undefined
            if (iPaymentSubscriptionCreate === null || iPaymentSubscriptionCreate === undefined) {
                throw new base_1.RequiredError('iPaymentSubscriptionCreate', 'Required parameter iPaymentSubscriptionCreate was null or undefined when calling createSubscription.');
            }
            const localVarPath = `/payment/subscriptions/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iPaymentSubscriptionCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iPaymentSubscriptionCreate !== undefined ? iPaymentSubscriptionCreate : {}) : (iPaymentSubscriptionCreate || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IPaymentPurchasedSubscriptionCreate} iPaymentPurchasedSubscriptionCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionCheckout: (iPaymentPurchasedSubscriptionCreate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iPaymentPurchasedSubscriptionCreate' is not null or undefined
            if (iPaymentPurchasedSubscriptionCreate === null || iPaymentPurchasedSubscriptionCreate === undefined) {
                throw new base_1.RequiredError('iPaymentPurchasedSubscriptionCreate', 'Required parameter iPaymentPurchasedSubscriptionCreate was null or undefined when calling createSubscriptionCheckout.');
            }
            const localVarPath = `/payment/purchases/subscription/checkout/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iPaymentPurchasedSubscriptionCreate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iPaymentPurchasedSubscriptionCreate !== undefined ? iPaymentPurchasedSubscriptionCreate : {}) : (iPaymentPurchasedSubscriptionCreate || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomer: (customerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new base_1.RequiredError('customerId', 'Required parameter customerId was null or undefined when calling deleteCustomer.');
            }
            const localVarPath = `/payment/customers/delete/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerById: (customerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new base_1.RequiredError('customerId', 'Required parameter customerId was null or undefined when calling getCustomerById.');
            }
            const localVarPath = `/payment/customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerByUserId: (userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling getCustomerByUserId.');
            }
            const localVarPath = `/payment/customers/getByUserId/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/payment/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById: (productId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new base_1.RequiredError('productId', 'Required parameter productId was null or undefined when calling getProductById.');
            }
            const localVarPath = `/payment/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/payment/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} subscriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionById: (subscriptionId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new base_1.RequiredError('subscriptionId', 'Required parameter subscriptionId was null or undefined when calling getSubscriptionById.');
            }
            const localVarPath = `/payment/subscriptions/{subscriptionId}`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptions: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/payment/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPurchasedProducts: (userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling getUserPurchasedProducts.');
            }
            const localVarPath = `/payment/purchases/products/getByUserId/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPurchasedSubscriptions: (userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling getUserPurchasedSubscriptions.');
            }
            const localVarPath = `/payment/purchases/subscription/getByUserId/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IPaymentStripeWebhookEvent} iPaymentStripeWebhookEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeWebhook: (iPaymentStripeWebhookEvent, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iPaymentStripeWebhookEvent' is not null or undefined
            if (iPaymentStripeWebhookEvent === null || iPaymentStripeWebhookEvent === undefined) {
                throw new base_1.RequiredError('iPaymentStripeWebhookEvent', 'Required parameter iPaymentStripeWebhookEvent was null or undefined when calling stripeWebhook.');
            }
            const localVarPath = `/payment/stripe/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iPaymentStripeWebhookEvent !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iPaymentStripeWebhookEvent !== undefined ? iPaymentStripeWebhookEvent : {}) : (iPaymentStripeWebhookEvent || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IPaymentCustomer} iPaymentCustomer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomer: (iPaymentCustomer, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iPaymentCustomer' is not null or undefined
            if (iPaymentCustomer === null || iPaymentCustomer === undefined) {
                throw new base_1.RequiredError('iPaymentCustomer', 'Required parameter iPaymentCustomer was null or undefined when calling updateCustomer.');
            }
            const localVarPath = `/payment/customers/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iPaymentCustomer !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iPaymentCustomer !== undefined ? iPaymentCustomer : {}) : (iPaymentCustomer || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.PaymentApiAxiosParamCreator = PaymentApiAxiosParamCreator;
/**
 * PaymentApi - functional programming interface
 * @export
 */
const PaymentApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveProduct(productId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).archiveProduct(productId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} subscriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveSubscription(subscriptionId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).archiveSubscription(subscriptionId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IPaymentCustomerCreate} iPaymentCustomerCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer(iPaymentCustomerCreate, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).createCustomer(iPaymentCustomerCreate, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IPaymentProductCreate} iPaymentProductCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(iPaymentProductCreate, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).createProduct(iPaymentProductCreate, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IPaymentProductCheckoutIntent} iPaymentProductCheckoutIntent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductCheckout(iPaymentProductCheckoutIntent, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).createProductCheckout(iPaymentProductCheckoutIntent, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IPaymentSubscriptionCreate} iPaymentSubscriptionCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription(iPaymentSubscriptionCreate, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).createSubscription(iPaymentSubscriptionCreate, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IPaymentPurchasedSubscriptionCreate} iPaymentPurchasedSubscriptionCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionCheckout(iPaymentPurchasedSubscriptionCreate, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).createSubscriptionCheckout(iPaymentPurchasedSubscriptionCreate, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomer(customerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).deleteCustomer(customerId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerById(customerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).getCustomerById(customerId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerByUserId(userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).getCustomerByUserId(userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).getCustomers(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById(productId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).getProductById(productId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).getProducts(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} subscriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionById(subscriptionId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).getSubscriptionById(subscriptionId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptions(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).getSubscriptions(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPurchasedProducts(userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).getUserPurchasedProducts(userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPurchasedSubscriptions(userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).getUserPurchasedSubscriptions(userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IPaymentStripeWebhookEvent} iPaymentStripeWebhookEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeWebhook(iPaymentStripeWebhookEvent, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).stripeWebhook(iPaymentStripeWebhookEvent, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IPaymentCustomer} iPaymentCustomer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomer(iPaymentCustomer, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.PaymentApiAxiosParamCreator(configuration).updateCustomer(iPaymentCustomer, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.PaymentApiFp = PaymentApiFp;
/**
 * PaymentApi - factory interface
 * @export
 */
const PaymentApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveProduct(productId, options) {
            return exports.PaymentApiFp(configuration).archiveProduct(productId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} subscriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveSubscription(subscriptionId, options) {
            return exports.PaymentApiFp(configuration).archiveSubscription(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IPaymentCustomerCreate} iPaymentCustomerCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer(iPaymentCustomerCreate, options) {
            return exports.PaymentApiFp(configuration).createCustomer(iPaymentCustomerCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IPaymentProductCreate} iPaymentProductCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(iPaymentProductCreate, options) {
            return exports.PaymentApiFp(configuration).createProduct(iPaymentProductCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IPaymentProductCheckoutIntent} iPaymentProductCheckoutIntent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductCheckout(iPaymentProductCheckoutIntent, options) {
            return exports.PaymentApiFp(configuration).createProductCheckout(iPaymentProductCheckoutIntent, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IPaymentSubscriptionCreate} iPaymentSubscriptionCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription(iPaymentSubscriptionCreate, options) {
            return exports.PaymentApiFp(configuration).createSubscription(iPaymentSubscriptionCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IPaymentPurchasedSubscriptionCreate} iPaymentPurchasedSubscriptionCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionCheckout(iPaymentPurchasedSubscriptionCreate, options) {
            return exports.PaymentApiFp(configuration).createSubscriptionCheckout(iPaymentPurchasedSubscriptionCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomer(customerId, options) {
            return exports.PaymentApiFp(configuration).deleteCustomer(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} customerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerById(customerId, options) {
            return exports.PaymentApiFp(configuration).getCustomerById(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerByUserId(userId, options) {
            return exports.PaymentApiFp(configuration).getCustomerByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers(options) {
            return exports.PaymentApiFp(configuration).getCustomers(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById(productId, options) {
            return exports.PaymentApiFp(configuration).getProductById(productId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts(options) {
            return exports.PaymentApiFp(configuration).getProducts(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} subscriptionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionById(subscriptionId, options) {
            return exports.PaymentApiFp(configuration).getSubscriptionById(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptions(options) {
            return exports.PaymentApiFp(configuration).getSubscriptions(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPurchasedProducts(userId, options) {
            return exports.PaymentApiFp(configuration).getUserPurchasedProducts(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPurchasedSubscriptions(userId, options) {
            return exports.PaymentApiFp(configuration).getUserPurchasedSubscriptions(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IPaymentStripeWebhookEvent} iPaymentStripeWebhookEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeWebhook(iPaymentStripeWebhookEvent, options) {
            return exports.PaymentApiFp(configuration).stripeWebhook(iPaymentStripeWebhookEvent, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IPaymentCustomer} iPaymentCustomer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomer(iPaymentCustomer, options) {
            return exports.PaymentApiFp(configuration).updateCustomer(iPaymentCustomer, options).then((request) => request(axios, basePath));
        },
    };
};
exports.PaymentApiFactory = PaymentApiFactory;
/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
class PaymentApi extends base_1.BaseAPI {
    /**
     *
     * @param {string} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    archiveProduct(productId, options) {
        return exports.PaymentApiFp(this.configuration).archiveProduct(productId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    archiveSubscription(subscriptionId, options) {
        return exports.PaymentApiFp(this.configuration).archiveSubscription(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IPaymentCustomerCreate} iPaymentCustomerCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    createCustomer(iPaymentCustomerCreate, options) {
        return exports.PaymentApiFp(this.configuration).createCustomer(iPaymentCustomerCreate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IPaymentProductCreate} iPaymentProductCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    createProduct(iPaymentProductCreate, options) {
        return exports.PaymentApiFp(this.configuration).createProduct(iPaymentProductCreate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IPaymentProductCheckoutIntent} iPaymentProductCheckoutIntent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    createProductCheckout(iPaymentProductCheckoutIntent, options) {
        return exports.PaymentApiFp(this.configuration).createProductCheckout(iPaymentProductCheckoutIntent, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IPaymentSubscriptionCreate} iPaymentSubscriptionCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    createSubscription(iPaymentSubscriptionCreate, options) {
        return exports.PaymentApiFp(this.configuration).createSubscription(iPaymentSubscriptionCreate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IPaymentPurchasedSubscriptionCreate} iPaymentPurchasedSubscriptionCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    createSubscriptionCheckout(iPaymentPurchasedSubscriptionCreate, options) {
        return exports.PaymentApiFp(this.configuration).createSubscriptionCheckout(iPaymentPurchasedSubscriptionCreate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    deleteCustomer(customerId, options) {
        return exports.PaymentApiFp(this.configuration).deleteCustomer(customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} customerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    getCustomerById(customerId, options) {
        return exports.PaymentApiFp(this.configuration).getCustomerById(customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    getCustomerByUserId(userId, options) {
        return exports.PaymentApiFp(this.configuration).getCustomerByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    getCustomers(options) {
        return exports.PaymentApiFp(this.configuration).getCustomers(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    getProductById(productId, options) {
        return exports.PaymentApiFp(this.configuration).getProductById(productId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    getProducts(options) {
        return exports.PaymentApiFp(this.configuration).getProducts(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} subscriptionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    getSubscriptionById(subscriptionId, options) {
        return exports.PaymentApiFp(this.configuration).getSubscriptionById(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    getSubscriptions(options) {
        return exports.PaymentApiFp(this.configuration).getSubscriptions(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    getUserPurchasedProducts(userId, options) {
        return exports.PaymentApiFp(this.configuration).getUserPurchasedProducts(userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    getUserPurchasedSubscriptions(userId, options) {
        return exports.PaymentApiFp(this.configuration).getUserPurchasedSubscriptions(userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IPaymentStripeWebhookEvent} iPaymentStripeWebhookEvent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    stripeWebhook(iPaymentStripeWebhookEvent, options) {
        return exports.PaymentApiFp(this.configuration).stripeWebhook(iPaymentStripeWebhookEvent, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IPaymentCustomer} iPaymentCustomer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    updateCustomer(iPaymentCustomer, options) {
        return exports.PaymentApiFp(this.configuration).updateCustomer(iPaymentCustomer, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.PaymentApi = PaymentApi;
/**
 * UserApi - axios parameter creator
 * @export
 */
const UserApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate: (token, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new base_1.RequiredError('token', 'Required parameter token was null or undefined when calling activate.');
            }
            const localVarPath = `/user/activate/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {TCredentials} tCredentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: (tCredentials, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'tCredentials' is not null or undefined
            if (tCredentials === null || tCredentials === undefined) {
                throw new base_1.RequiredError('tCredentials', 'Required parameter tCredentials was null or undefined when calling authenticate.');
            }
            const localVarPath = `/user/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof tCredentials !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(tCredentials !== undefined ? tCredentials : {}) : (tCredentials || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {INewUser} iNewUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewUser: (iNewUser, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iNewUser' is not null or undefined
            if (iNewUser === null || iNewUser === undefined) {
                throw new base_1.RequiredError('iNewUser', 'Required parameter iNewUser was null or undefined when calling createNewUser.');
            }
            const localVarPath = `/user/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iNewUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iNewUser !== undefined ? iNewUser : {}) : (iNewUser || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicate: (userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling duplicate.');
            }
            const localVarPath = `/user/duplicate/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableRoles: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/get-available-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: (userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling getUserById.');
            }
            const localVarPath = `/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSettings: (userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling getUserSettings.');
            }
            const localVarPath = `/user/settings/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IUserRole} iUserRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giveRoleToUser: (iUserRole, userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iUserRole' is not null or undefined
            if (iUserRole === null || iUserRole === undefined) {
                throw new base_1.RequiredError('iUserRole', 'Required parameter iUserRole was null or undefined when calling giveRoleToUser.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling giveRoleToUser.');
            }
            const localVarPath = `/user/add-role/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iUserRole !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iUserRole !== undefined ? iUserRole : {}) : (iUserRole || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {INewUser} iNewUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNewUser: (iNewUser, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iNewUser' is not null or undefined
            if (iNewUser === null || iNewUser === undefined) {
                throw new base_1.RequiredError('iNewUser', 'Required parameter iNewUser was null or undefined when calling registerNewUser.');
            }
            const localVarPath = `/user/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iNewUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iNewUser !== undefined ? iNewUser : {}) : (iNewUser || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IUserRole} iUserRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRoleFromUser: (iUserRole, userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iUserRole' is not null or undefined
            if (iUserRole === null || iUserRole === undefined) {
                throw new base_1.RequiredError('iUserRole', 'Required parameter iUserRole was null or undefined when calling removeRoleFromUser.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling removeRoleFromUser.');
            }
            const localVarPath = `/user/remove-role/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iUserRole !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iUserRole !== undefined ? iUserRole : {}) : (iUserRole || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUser: (userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling removeUser.');
            }
            const localVarPath = `/user/delete/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IUserUserPasswordResetRequest} iUserUserPasswordResetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordRequest: (iUserUserPasswordResetRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iUserUserPasswordResetRequest' is not null or undefined
            if (iUserUserPasswordResetRequest === null || iUserUserPasswordResetRequest === undefined) {
                throw new base_1.RequiredError('iUserUserPasswordResetRequest', 'Required parameter iUserUserPasswordResetRequest was null or undefined when calling resetPasswordRequest.');
            }
            const localVarPath = `/user/resetPasswordRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iUserUserPasswordResetRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iUserUserPasswordResetRequest !== undefined ? iUserUserPasswordResetRequest : {}) : (iUserUserPasswordResetRequest || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByUsername: (username, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new base_1.RequiredError('username', 'Required parameter username was null or undefined when calling searchByUsername.');
            }
            const localVarPath = `/user/search/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IUserUserUpdate} iUserUserUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: (iUserUserUpdate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iUserUserUpdate' is not null or undefined
            if (iUserUserUpdate === null || iUserUserUpdate === undefined) {
                throw new base_1.RequiredError('iUserUserUpdate', 'Required parameter iUserUserUpdate was null or undefined when calling update.');
            }
            const localVarPath = `/user/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iUserUserUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iUserUserUpdate !== undefined ? iUserUserUpdate : {}) : (iUserUserUpdate || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IUserUserPasswordResetUpdate} iUserUserPasswordResetUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResettedPassword: (iUserUserPasswordResetUpdate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iUserUserPasswordResetUpdate' is not null or undefined
            if (iUserUserPasswordResetUpdate === null || iUserUserPasswordResetUpdate === undefined) {
                throw new base_1.RequiredError('iUserUserPasswordResetUpdate', 'Required parameter iUserUserPasswordResetUpdate was null or undefined when calling updateResettedPassword.');
            }
            const localVarPath = `/user/updateResettedPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iUserUserPasswordResetUpdate !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iUserUserPasswordResetUpdate !== undefined ? iUserUserPasswordResetUpdate : {}) : (iUserUserPasswordResetUpdate || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IApiUserUpdateRole} iApiUserUpdateRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleOfUser: (iApiUserUpdateRole, userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iApiUserUpdateRole' is not null or undefined
            if (iApiUserUpdateRole === null || iApiUserUpdateRole === undefined) {
                throw new base_1.RequiredError('iApiUserUpdateRole', 'Required parameter iApiUserUpdateRole was null or undefined when calling updateRoleOfUser.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError('userId', 'Required parameter userId was null or undefined when calling updateRoleOfUser.');
            }
            const localVarPath = `/user/update-role/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iApiUserUpdateRole !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iApiUserUpdateRole !== undefined ? iApiUserUpdateRole : {}) : (iApiUserUpdateRole || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IUserUserUpdateSettings} iUserUserUpdateSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings: (iUserUserUpdateSettings, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iUserUserUpdateSettings' is not null or undefined
            if (iUserUserUpdateSettings === null || iUserUserUpdateSettings === undefined) {
                throw new base_1.RequiredError('iUserUserUpdateSettings', 'Required parameter iUserUserUpdateSettings was null or undefined when calling updateSettings.');
            }
            const localVarPath = `/user/updateSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iUserUserUpdateSettings !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iUserUserUpdateSettings !== undefined ? iUserUserUpdateSettings : {}) : (iUserUserUpdateSettings || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IUserUpdateTourProgress} iUserUpdateTourProgress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTourProgress: (iUserUpdateTourProgress, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iUserUpdateTourProgress' is not null or undefined
            if (iUserUpdateTourProgress === null || iUserUpdateTourProgress === undefined) {
                throw new base_1.RequiredError('iUserUpdateTourProgress', 'Required parameter iUserUpdateTourProgress was null or undefined when calling updateTourProgress.');
            }
            const localVarPath = `/user/updateTourProgress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iUserUpdateTourProgress !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iUserUpdateTourProgress !== undefined ? iUserUpdateTourProgress : {}) : (iUserUpdateTourProgress || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IUserPasswordResetToken} iUserPasswordResetToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePasswordRequestToken: (iUserPasswordResetToken, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iUserPasswordResetToken' is not null or undefined
            if (iUserPasswordResetToken === null || iUserPasswordResetToken === undefined) {
                throw new base_1.RequiredError('iUserPasswordResetToken', 'Required parameter iUserPasswordResetToken was null or undefined when calling validatePasswordRequestToken.');
            }
            const localVarPath = `/user/validatePasswordRequestToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof iUserPasswordResetToken !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(iUserPasswordResetToken !== undefined ? iUserPasswordResetToken : {}) : (iUserPasswordResetToken || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.UserApiAxiosParamCreator = UserApiAxiosParamCreator;
/**
 * UserApi - functional programming interface
 * @export
 */
const UserApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate(token, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).activate(token, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {TCredentials} tCredentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(tCredentials, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).authenticate(tCredentials, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {INewUser} iNewUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewUser(iNewUser, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).createNewUser(iNewUser, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicate(userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).duplicate(userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).getAll(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableRoles(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).getAvailableRoles(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).getUserById(userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSettings(userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).getUserSettings(userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IUserRole} iUserRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giveRoleToUser(iUserRole, userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).giveRoleToUser(iUserRole, userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {INewUser} iNewUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNewUser(iNewUser, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).registerNewUser(iNewUser, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IUserRole} iUserRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRoleFromUser(iUserRole, userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).removeRoleFromUser(iUserRole, userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUser(userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).removeUser(userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IUserUserPasswordResetRequest} iUserUserPasswordResetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordRequest(iUserUserPasswordResetRequest, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).resetPasswordRequest(iUserUserPasswordResetRequest, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByUsername(username, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).searchByUsername(username, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IUserUserUpdate} iUserUserUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(iUserUserUpdate, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).update(iUserUserUpdate, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IUserUserPasswordResetUpdate} iUserUserPasswordResetUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResettedPassword(iUserUserPasswordResetUpdate, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).updateResettedPassword(iUserUserPasswordResetUpdate, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IApiUserUpdateRole} iApiUserUpdateRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleOfUser(iApiUserUpdateRole, userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).updateRoleOfUser(iApiUserUpdateRole, userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IUserUserUpdateSettings} iUserUserUpdateSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(iUserUserUpdateSettings, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).updateSettings(iUserUserUpdateSettings, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IUserUpdateTourProgress} iUserUpdateTourProgress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTourProgress(iUserUpdateTourProgress, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).updateTourProgress(iUserUpdateTourProgress, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {IUserPasswordResetToken} iUserPasswordResetToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePasswordRequestToken(iUserPasswordResetToken, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.UserApiAxiosParamCreator(configuration).validatePasswordRequestToken(iUserPasswordResetToken, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.UserApiFp = UserApiFp;
/**
 * UserApi - factory interface
 * @export
 */
const UserApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate(token, options) {
            return exports.UserApiFp(configuration).activate(token, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {TCredentials} tCredentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(tCredentials, options) {
            return exports.UserApiFp(configuration).authenticate(tCredentials, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {INewUser} iNewUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewUser(iNewUser, options) {
            return exports.UserApiFp(configuration).createNewUser(iNewUser, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicate(userId, options) {
            return exports.UserApiFp(configuration).duplicate(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options) {
            return exports.UserApiFp(configuration).getAll(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableRoles(options) {
            return exports.UserApiFp(configuration).getAvailableRoles(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(userId, options) {
            return exports.UserApiFp(configuration).getUserById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSettings(userId, options) {
            return exports.UserApiFp(configuration).getUserSettings(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IUserRole} iUserRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giveRoleToUser(iUserRole, userId, options) {
            return exports.UserApiFp(configuration).giveRoleToUser(iUserRole, userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {INewUser} iNewUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNewUser(iNewUser, options) {
            return exports.UserApiFp(configuration).registerNewUser(iNewUser, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IUserRole} iUserRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRoleFromUser(iUserRole, userId, options) {
            return exports.UserApiFp(configuration).removeRoleFromUser(iUserRole, userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUser(userId, options) {
            return exports.UserApiFp(configuration).removeUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IUserUserPasswordResetRequest} iUserUserPasswordResetRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordRequest(iUserUserPasswordResetRequest, options) {
            return exports.UserApiFp(configuration).resetPasswordRequest(iUserUserPasswordResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchByUsername(username, options) {
            return exports.UserApiFp(configuration).searchByUsername(username, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IUserUserUpdate} iUserUserUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(iUserUserUpdate, options) {
            return exports.UserApiFp(configuration).update(iUserUserUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IUserUserPasswordResetUpdate} iUserUserPasswordResetUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResettedPassword(iUserUserPasswordResetUpdate, options) {
            return exports.UserApiFp(configuration).updateResettedPassword(iUserUserPasswordResetUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IApiUserUpdateRole} iApiUserUpdateRole
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleOfUser(iApiUserUpdateRole, userId, options) {
            return exports.UserApiFp(configuration).updateRoleOfUser(iApiUserUpdateRole, userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IUserUserUpdateSettings} iUserUserUpdateSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(iUserUserUpdateSettings, options) {
            return exports.UserApiFp(configuration).updateSettings(iUserUserUpdateSettings, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IUserUpdateTourProgress} iUserUpdateTourProgress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTourProgress(iUserUpdateTourProgress, options) {
            return exports.UserApiFp(configuration).updateTourProgress(iUserUpdateTourProgress, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {IUserPasswordResetToken} iUserPasswordResetToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePasswordRequestToken(iUserPasswordResetToken, options) {
            return exports.UserApiFp(configuration).validatePasswordRequestToken(iUserPasswordResetToken, options).then((request) => request(axios, basePath));
        },
    };
};
exports.UserApiFactory = UserApiFactory;
/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
class UserApi extends base_1.BaseAPI {
    /**
     *
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    activate(token, options) {
        return exports.UserApiFp(this.configuration).activate(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {TCredentials} tCredentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    authenticate(tCredentials, options) {
        return exports.UserApiFp(this.configuration).authenticate(tCredentials, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {INewUser} iNewUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    createNewUser(iNewUser, options) {
        return exports.UserApiFp(this.configuration).createNewUser(iNewUser, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    duplicate(userId, options) {
        return exports.UserApiFp(this.configuration).duplicate(userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    getAll(options) {
        return exports.UserApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    getAvailableRoles(options) {
        return exports.UserApiFp(this.configuration).getAvailableRoles(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    getUserById(userId, options) {
        return exports.UserApiFp(this.configuration).getUserById(userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    getUserSettings(userId, options) {
        return exports.UserApiFp(this.configuration).getUserSettings(userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IUserRole} iUserRole
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    giveRoleToUser(iUserRole, userId, options) {
        return exports.UserApiFp(this.configuration).giveRoleToUser(iUserRole, userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {INewUser} iNewUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    registerNewUser(iNewUser, options) {
        return exports.UserApiFp(this.configuration).registerNewUser(iNewUser, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IUserRole} iUserRole
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    removeRoleFromUser(iUserRole, userId, options) {
        return exports.UserApiFp(this.configuration).removeRoleFromUser(iUserRole, userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    removeUser(userId, options) {
        return exports.UserApiFp(this.configuration).removeUser(userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IUserUserPasswordResetRequest} iUserUserPasswordResetRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    resetPasswordRequest(iUserUserPasswordResetRequest, options) {
        return exports.UserApiFp(this.configuration).resetPasswordRequest(iUserUserPasswordResetRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    searchByUsername(username, options) {
        return exports.UserApiFp(this.configuration).searchByUsername(username, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IUserUserUpdate} iUserUserUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    update(iUserUserUpdate, options) {
        return exports.UserApiFp(this.configuration).update(iUserUserUpdate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IUserUserPasswordResetUpdate} iUserUserPasswordResetUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    updateResettedPassword(iUserUserPasswordResetUpdate, options) {
        return exports.UserApiFp(this.configuration).updateResettedPassword(iUserUserPasswordResetUpdate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IApiUserUpdateRole} iApiUserUpdateRole
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    updateRoleOfUser(iApiUserUpdateRole, userId, options) {
        return exports.UserApiFp(this.configuration).updateRoleOfUser(iApiUserUpdateRole, userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IUserUserUpdateSettings} iUserUserUpdateSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    updateSettings(iUserUserUpdateSettings, options) {
        return exports.UserApiFp(this.configuration).updateSettings(iUserUserUpdateSettings, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IUserUpdateTourProgress} iUserUpdateTourProgress
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    updateTourProgress(iUserUpdateTourProgress, options) {
        return exports.UserApiFp(this.configuration).updateTourProgress(iUserUpdateTourProgress, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {IUserPasswordResetToken} iUserPasswordResetToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    validatePasswordRequestToken(iUserPasswordResetToken, options) {
        return exports.UserApiFp(this.configuration).validatePasswordRequestToken(iUserPasswordResetToken, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.UserApi = UserApi;
